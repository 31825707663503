import { useEffect, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TableContainer,
  Typography,
} from "@mui/material";
import Loader from "src/components/Loader";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { KeyboardArrowDown } from "@mui/icons-material";
import useCloumn from "src/components/Common/Hooks/useColumn";
import { usePlayerStats } from "src/helpers/context/PlayersStats/PlayerStatsContext";

const StickyTableCell = withStyles((theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    backgroundColor: "#ffffff",
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    backgroundColor: "#ffffff",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  head: {},
  cell: {},
}));

const PlayerTable = ({ tableData, loading }) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0, // initial page index
    pageSize: 10, // default page size
  });

  const [displayedData, setDisplayedData] = useState([]); // Store accumulated data

  const { cricketColumn } = useCloumn();
  const { selectedStats } = usePlayerStats();

  // When pagination changes, append new data
  useEffect(() => {
    if (tableData) {
      const newData = tableData?.slice(
        0,
        (pagination.pageIndex + 1) * pagination.pageSize,
      );
      setDisplayedData(newData);
    }
  }, [pagination, tableData]);

  const pageCount = Math.ceil(tableData?.length / pagination?.pageSize);

  const table = useReactTable({
    data: displayedData, // Use accumulated data
    columns: cricketColumn,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    onPaginationChange: setPagination,
    state: { pagination },
    manualPagination: true,
    pageCount,
  });

  const highlightedIndex = selectedStats === "team" ? 2 : 3;

  return (
    <Box className="new-landing-table-container-details">
      <TableContainer
        className="no-border-radius"
        sx={{ maxHeight: 603, position: "relative" }}
      >
        <Table className="new-landing-landing-table">
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 10 }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  if (index <= 1) {
                    return (
                      <StickyTableCell
                        key={header.id}
                        style={{
                          position: "sticky",
                          left: index === 0 ? 0 : "40px", // Adjust left offset for multiple sticky columns
                          zIndex: 9,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </StickyTableCell>
                    );
                  }

                  return (
                    <TableCell
                      key={header.id}
                      style={{
                        backgroundColor:
                          index === highlightedIndex && "#4455c7",
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows.length > 0 ? (
              <>
                {table.getRowModel().rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? "rgb(255, 255, 255)"
                          : "rgb(247, 247, 248)",
                    }}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      const isSticky = index <= 1;
                      const leftOffset = index === 0 ? 0 : "40px";
                      const isOddRow = row.index % 2 !== 0;

                      return (
                        <TableCell
                          key={cell.id}
                          style={{
                            position: isSticky ? "sticky" : "relative",
                            left: isSticky ? leftOffset : "auto",
                            zIndex: isSticky ? 9 : "auto",
                            backgroundColor: isSticky
                              ? isOddRow
                                ? "rgb(247, 247, 248)" // Alternate row color for sticky cells
                                : "rgb(255, 255, 255)"
                              : index === highlightedIndex
                                ? "rgba(0, 55, 100, 0.1)"
                                : "inherit", // Non-sticky cells inherit background
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}

                {table.getCanNextPage() && (
                  <TableRow sx={{ position: "sticky", bottom: -1, zIndex: 99 }}>
                    <TableCell
                      colSpan={12}
                      align="center"
                      className="show-more-cell"
                    >
                      <Typography
                        className="show-more-text"
                        onClick={() => table.nextPage()}
                        sx={{ cursor: "pointer" }}
                      >
                        Show more <KeyboardArrowDown />
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PlayerTable;
