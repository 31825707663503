import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, SwipeableDrawer, Tab, Tabs } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import pageHeaderIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import BottomSheet from "../../../../assets/images/filterBottomsheet.png";
import BottomArrowSheet from "../../../../assets/images/bottomSheetDownArrow.png";
import AdBannner from "../../AdBanner/AdBanner";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import { Config } from "src/helpers/context";
import Select, { components } from "react-select";
import HomeLandingRight from "./homeLandingRight";
import HomeLandingLeft from "./homeLandingLeft";
import OurPartner from "../../ourPartners";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import LandingICCRankingDetails from "./homeLandingRight/landingICCRankingDetails";
import LandingTableDetails from "./homeLandingRight/landingTableDetails";
import LandingFixturesResultDetails from "./homeLandingRight/landingFixturesResultsDetails";
import ViewExpertTips from "../teamCommonComponent/viewExpertTips";
import "./homeLanding.scss";
import SportsHeader from "../teamCommonComponent/sportsHeader";
import { SPORTS_TYPE } from "../teamCommonComponent/newSportsUtils";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const HomeLanding = () => {
  const params = useParams();
  const location = useLocation();
  const searchParamsMenu = new URLSearchParams(location.search);
  const touranamentId = searchParamsMenu.get("touranamentId");
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const expertTipsCount = useSelector(
    (state) => state?.reduxData?.viewExpertTipsCount,
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [tabvalue, setTabValue] = useState(0);
  const [externalTeamData, setExternalTeamData] = useState([]);
  const [externalTeamCount, setExternalTeamCount] = useState(0);
  const [externalTeamPage, setExternalTeamPage] = useState(0);
  const [isTeamSearch, setIsTeamSearch] = useState("");
  const [searchTeam, setSearchTeam] = useState([]);
  const [searchTeamCount, setSearchTeamCount] = useState(0);
  const [searchTeamPage, setSearchTeamPage] = useState(0);
  const [selectTeam, setSelectTeam] = useState(null);

  const teamRowperPage = 20;

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const fetchAllTeam = useCallback(
    async (page) => {
      // Clear the data when fetching starts
      if (page === 0) setExternalTeamData([]);

      const passApi = `public/v2/team?SportId=${params?.sportsId}&limit=${teamRowperPage}&offset=${page}&tournamentId=${
        touranamentId === "0" ? "" : touranamentId
      }`;

      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        const count = data?.result?.count / teamRowperPage;
        const newdata = data?.result?.rows?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));

        const filterdata = newdata?.filter((item) => item?.value !== 0);
        const mergeData = _.unionBy(
          page === 0 ? [] : externalTeamData,
          filterdata,
          "value",
        );
        const sortedData = mergeData.sort((a, b) =>
          a.label.localeCompare(b.label),
        );

        sortedData.unshift({
          label: "All Teams",
          value: 0,
        });

        setExternalTeamData(_.uniqBy(sortedData, "value"));
        setExternalTeamCount(Math.ceil(count));
      }
    },
    [params?.sportsId, touranamentId, externalTeamData],
  );

  const handleOnScrollBottomExternalTeam = (e) => {
    if (
      isTeamSearch !== "" &&
      searchTeamCount !== Math.ceil(searchTeamPage / teamRowperPage + 1)
    ) {
      handleTeamInputChange(searchTeamPage + teamRowperPage, isTeamSearch);
      setSearchTeamPage((prevPage) => prevPage + teamRowperPage);
    } else if (
      externalTeamCount !== Math.ceil(externalTeamPage / teamRowperPage) &&
      isTeamSearch === ""
    ) {
      fetchAllTeam(externalTeamPage + teamRowperPage);
      setExternalTeamPage((prevPage) => prevPage + teamRowperPage);
    }
  };

  const handleTeamInputChange = (page, value) => {
    const passApi = `public/v2/team?SportId=${params?.sportsId}&limit=${teamRowperPage}&offset=${page}&search=${value}&tournamentId=${touranamentId === "0" ? "" : touranamentId}`;
    axiosInstance.get(passApi).then(({ data, status }) => {
      if (status === 200) {
        const count = data?.result?.count / teamRowperPage;
        const newdata = data?.result?.rows?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));

        const filterdata = newdata?.filter((item) => item?.value !== 0);
        const mergeData = _.unionBy(searchTeam, filterdata, "value");
        const sortedData = mergeData.sort((a, b) =>
          a.label.localeCompare(b.label),
        );

        sortedData.unshift({
          label: "All Teams",
          value: 0,
        });

        setSearchTeam(_.uniqBy(sortedData, "value"));
        setSearchTeamCount(Math.ceil(count));
        setIsTeamSearch(value);
      }
    });
  };

  const handleExternalTeamChange = (selectedOption) => {
    setSelectTeam(selectedOption?.value);
  };

  useEffect(() => {
    // Clear the selected team and data when tournament ID changes
    setSelectTeam(0);
    setExternalTeamData([]); // Clear the externalTeamData state
    fetchAllTeam(0); // Fetch new data based on updated tournament ID
  }, [touranamentId]);

  const handleOpenBottomSheet = () => {
    setOpenBottomSheet(true);
  };

  const togglehandleOpenBottomSheet = () => {
    setOpenBottomSheet(false);
  };

  const cricketTabData = [
    {
      id: 0,
      name: "Icc Rankings",
    },
    {
      id: 1,
      name: "table",
    },
    {
      id: 2,
      name: "Fixtures & Results",
    },
  ];

  const nrlTabData = [
    {
      id: 1,
      name: "NRL Ladder",
    },
    {
      id: 2,
      name: "Fixtures & Results",
    },
  ];

  const aflTabData = [
    {
      id: 1,
      name: "AFL Ladder",
    },
    {
      id: 2,
      name: "Fixtures & Results",
    },
  ];

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  let tabData = [];
  const sportsId = Number(params?.sportsId);

  if (sportsId === SPORTS_TYPE.circket) {
    tabData = cricketTabData;
  }

  if (sportsId === SPORTS_TYPE.rugbyLeague) {
    tabData = nrlTabData;
  }

  if (sportsId === SPORTS_TYPE.australianrules) {
    tabData = aflTabData;
  }

  // Setting default tab on mount

  useEffect(() => {
    if (tabData?.length > 0) {
      if (sportsId === SPORTS_TYPE.circket) {
        setTabValue(0);
      } else {
        setTabValue(1);
      }
    }
  }, []);

  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config.release === "IN" ? pageHeaderIndia : banner,
          )}
        </Box>
        <Box>
          <SportsHeader
            localesData={localesData}
            params={params}
            expertTipsCount={expertTipsCount}
            screenWidth={screenWidth}
          />
        </Box>
        <Box className="homepage-landing-page-wrap">
          <Box className="homepage-landing-header">
            <Box className="team-select-wrap">
              <Select
                className={`React team-selectbox-wrap`}
                classNamePrefix="select"
                placeholder="All Teams"
                onMenuScrollToBottom={handleOnScrollBottomExternalTeam}
                onInputChange={(value) => handleTeamInputChange(0, value)}
                value={
                  isTeamSearch
                    ? searchTeam?.find((item) => item?.value === selectTeam)
                    : externalTeamData?.find(
                        (item) => item?.value === selectTeam,
                      )
                }
                onChange={(e) => handleExternalTeamChange(e)}
                options={isTeamSearch ? searchTeam : externalTeamData}
                components={{ DropdownIndicator }}
              />
            </Box>
            {screenWidth >= 1023 && expertTipsCount > 0 && (
              <Box>
                <ViewExpertTips />
              </Box>
            )}
          </Box>
          <Box className="homepage-landing-content">
            <Box className="homepage-left-side">
              <HomeLandingLeft selectTeam={selectTeam} />
            </Box>
            {screenWidth > 1080 && (
              <Box className="homepage-right-side">
                <HomeLandingRight />
              </Box>
            )}
          </Box>
        </Box>
        <OurPartner />
        {screenWidth <= 1080 && (
          <Box
            className="bottom-sheet-wrap"
            onClick={() => handleOpenBottomSheet()}
          >
            <img src={BottomSheet} alt="icon" />
          </Box>
        )}
        <SwipeableDrawer
          anchor="bottom"
          open={openBottomSheet}
          onClose={togglehandleOpenBottomSheet}
          onOpen={openBottomSheet}
          className="homepage-bottom-sheet"
        >
          <Box
            role="presentation"
            // onClick={togglehandleOpenBottomSheet}
            // onKeyDown={togglehandleOpenBottomSheet}
          >
            <Box className="bottom-sheet-content-wrap">
              <Box className="bottom-sheet-top-content">
                <Box
                  className="bottom-sheet-img-wrap"
                  onClick={togglehandleOpenBottomSheet}
                >
                  <img src={BottomArrowSheet} alt="icon" />
                </Box>
                <Box className="team-sports-tab">
                  <Tabs
                    value={tabvalue}
                    variant="scrollable"
                    scrollButtons={false}
                    indicatorColor="primary"
                    textColor="primary"
                    className="teamsport-tab-detail"
                  >
                    {tabData?.map((item, index) => {
                      return (
                        <Box key={index}>
                          <Tab
                            label={
                              <Box className="tab-label">
                                <span className="label-name">{item?.name}</span>
                              </Box>
                            }
                            value={item?.id}
                            className={item?.id === tabvalue ? "active " : ""}
                            onChange={(event, newValue) =>
                              handleTabChange(event, item?.id)
                            }
                          />
                        </Box>
                      );
                    })}
                  </Tabs>
                </Box>
              </Box>
              <Box>
                {tabvalue === 0 ? (
                  <LandingICCRankingDetails />
                ) : tabvalue === 1 ? (
                  <LandingTableDetails />
                ) : tabvalue === 2 ? (
                  <LandingFixturesResultDetails />
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </SwipeableDrawer>
      </Box>
    </>
  );
};

export default HomeLanding;
