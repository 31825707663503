import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment";
import { useNavigate, useParams } from "react-router";
import "./landingFixturesResultsDetails.scss";
import SportCard from "./sportFixtureCard";
import {
  getSportsType,
  SPORTS_TYPE,
} from "../../../teamCommonComponent/newSportsUtils";

const LandingFixturesResultDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fixturesResultData, setFixturesResultData] = useState([]);

  const tabs = [
    { label: "Fixtures", id: 1 },
    { label: "Results", id: 2 },
  ];
  // let sportName = "";
  // const isSportCricket = Number(params.sportsId) === SPORTS_TYPE.circket;
  // const isSportNRL = Number(params.sportsId) === SPORTS_TYPE.rugbyLeague;

  // if(isSportCricket){
  //   sportName = "cricket";
  // }

  // if(isSportNRL){   }

  const handleTabClick = (tab) => {
    setActiveTab(tab?.id);
  };

  useEffect(() => {
    fetchFixturesList();
  }, [activeTab, params?.sportsId, params?.sportsTypeName]);

  const fetchFixturesList = async () => {
    setLoading(true);
    const todayDate = moment().tz("Asia/Calcutta").format("YYYY-MM-DD");
    const sportType = getSportsType(params?.sportsTypeName);
    const passApi =
      activeTab === 1
        ? `public/${sportType}/event?startDate=${todayDate}&endDate=&timezone=Asia/Calcutta&SportId=${params?.sportsId}&oddCheck=true&marketId=1&limit=8&offset=0&liveOdd=true&isAdmin=false&isHome=true&isGraph=false&status=&CricketTournamentId=`
        : `public/${sportType}/event?startDate=&endDate=${todayDate}&timezone=Asia/Calcutta&SportId=${params?.sportsId}&oddCheck=true&marketId=1&limit=8&offset=0&liveOdd=true&isAdmin=false&isHome=false&isGraph=false&status=finished&CricketTournamentId=`;
    const { status, data } = await axiosInstance.get(passApi);
    try {
      if (status === 200) {
        setFixturesResultData(data?.result?.rows);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const sportsId = Number(params?.sportsId);

  const handleNaviageFixture = () => {
    let sportName =
      params?.sportsId === "4"
        ? "cricket"
        : params?.sportsId === "10"
          ? "basketball"
          : params?.sportsId === "15"
            ? "americanfootball"
            : params?.sportsId === "9"
              ? "australianrules"
              : params?.sportsId === "16"
                ? "golf"
                : params?.sportsId === "7"
                  ? "tennis"
                  : params?.sportsId === "11"
                    ? "baseball"
                    : params?.sportsId === "17"
                      ? "icehockey"
                      : params?.sportsId === "6"
                        ? "boxing"
                        : params?.sportsId === "5"
                          ? "mma"
                          : params?.sportsId === "8"
                            ? "soccer"
                            : params?.sportsId === "12"
                              ? "rugbyleague"
                              : params?.sportsId === "13"
                                ? "rugbyunion"
                                : "rugbyunionsevens";
    if (sportsId === SPORTS_TYPE.circket) {
      navigate(
        `/all-sports/${sportName}/${params?.sportsId}/odds/0/false?menu=fixtures_results&touranamentId=0&touranamentName=All+Leagues`,
      );
    } else {
      navigate(`/teamsports/${sportName}/odds/0/false`);
    }
  };

  return (
    <>
      <Box className="landing-fixtures-results-details-wrap">
        <Box className="tab-container">
          <Box className="tab-wrap tab-wrap-bottom">
            {tabs?.map((tab, index) => (
              <Box
                key={index}
                className={`tab-details ${
                  activeTab === tab?.id ? "tab-active" : ""
                }`}
                onClick={() => handleTabClick(tab)}
              >
                <p className="tab-text">{tab?.label}</p>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className="component-wrap upcoming-fixture-result-wrap">
          <SportCard
            isSliderLoading={loading}
            selectedSportData={fixturesResultData}
            type={activeTab === 1 ? "fixture" : "result"}
          />
        </Box>
        <Box className="view-all-wrap">
          <Button
            variant="contained"
            className="view-all-fixture"
            onClick={() => {
              handleNaviageFixture();
            }}
          >
            View all fixtures
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LandingFixturesResultDetails;
