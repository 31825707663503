import React, { useState, createContext, useEffect } from "react";
import { useRoutes, useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import GlobalStyles from "src/components/GlobalStyles";
import theme from "src/theme";
import {
  publicRoute,
  authRoutes,
  userRoutes,
  RestrictedRoute,
} from "src/routes";
import { useDispatch, useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context";
import {
  fetchAFData,
  fetchARData,
  fetchBaseballData,
  fetchBasketballData,
  fetchBoxingData,
  fetchCricketData,
  fetchGolfData,
  fetchIceHockeyData,
  fetchMMAData,
  fetchRLData,
  fetchRUData,
  fetchSoccerData,
  fetchTennisData,
} from "../src/helpers/store/Actions/TeamSportEvents";
import messagesEn from "src/helpers/locales/en.json";
import messagesDe from "src/helpers/locales/de.json";
import "react-perfect-scrollbar/dist/css/styles.css";
import "src/assets/scss/global.scss";
import Loader from "src/components/Loader";
import axiosInstance from "./helpers/Axios/axiosInstance";
import { EventEmitter } from "src/helpers/EventEmitter";
import generateSitemap from "./generateSitemap";
// import fs from "fs";
import moment from "moment-timezone";
import sitemapurl from "src/helpers/constants/sitemapurl";
import { fetchRestrictedRoute } from "./helpers/store/Actions/RestrictedRoute";
import { useTimer } from "react-timer-hook";
import { useMemo } from "react";
import AudioManager from "./assets/audio/NotificationAlert.mp3";
import TawkToScript from "./views/component/RacingOddsComparison/tawkToScript";

const Context = createContext();

const App = () => {
  const navigate = useNavigate();
  const reduxAuth = useSelector((state) => state?.reduxData?.authData);
  const userData = useSelector((state) => state?.reduxData?.SubscripitionData);
  const localAuth = fetchFromStorage("auth_token");
  const [checkCountryCode, setcheckCountryCode] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [urlData, seturlData] = useState("");
  const [raceURLData, setraceURLData] = useState("");
  const [raceData, setraceData] = useState([]);
  const [cricketData, setCricketData] = useState([]);
  const [RLData, setRLData] = useState([]);
  const [RUData, setRUData] = useState([]);
  const [NBAData, setNBAData] = useState([]);
  const [isMaintenanceOn, setMaintenanceOn] = useState(false);
  const auth = reduxAuth ? reduxAuth : localAuth;
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const referralCode = searchParams.get("referral");
  const referralType = searchParams.get("referral_type");

  const preloadImages = (urls) => {
    urls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  useEffect(() => {
    const imageUrls = [
      "./assets/images/signup-img/auth-bg.webp",
      "./assets/images/signup-img/auth-left.webp",
      "./assets/images/login-popup-desktop.png",
      "./assets/images/login-popup-mobile.png",
    ];
    preloadImages(imageUrls);
  }, []);

  useEffect(() => {
    if (
      auth &&
      userData?.isPremiumUser == true &&
      userData?.subscription?.SubscriptionPlan?.key == "odds"
    ) {
      fetchRaceAlertList();
    }
  }, [
    auth,
    userData?.isPremiumUser,
    userData?.subscription?.SubscriptionPlan?.key,
  ]);

  const fetchRaceAlertList = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/raceAlert/user/list`);
      if (status === 200) {
        const raceDetails = data?.data;
        fetchAlertSettingsList(raceDetails);
      } else {
      }
    } catch (err) {}
  };

  const fetchWatchListAlertList = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        "/watchlist/user/alertList",
      );
      if (status === 200) {
        const responseData = data?.data;
        for (const item of responseData) {
          if (
            item?.type === "runner" &&
            item?.RacingParticipant?.race?.isOdd === 1
          ) {
            await handleItem(item);
          } else if (item?.type === "race" && item?.race?.isOdd === 1) {
            await handleItem(item);
          }
        }
      }
    } catch (err) {
      console.error("Error fetching watchlist:", err);
    }
  };

  const handleItem = async (item) => {
    try {
      alert(`Your Watchlist Odds are Updated for ${item?.type}`);
      playSound();
      await updateApiCall(item?.id);
    } catch (err) {
      console.error("Error handling item:", err);
    }
  };

  const updateApiCall = async (id) => {
    try {
      await axiosInstance.put("/watchlist/user/updateList", {
        id,
        status: "sent",
      });
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const playSound = () => {
    const audio = new Audio(AudioManager);
    audio.play();
  };
  useEffect(() => {
    if (
      auth &&
      userData?.isPremiumUser === true &&
      userData?.subscription?.SubscriptionPlan?.key === "odds"
    ) {
      fetchWatchListAlertList();
      const intervalId = setInterval(fetchWatchListAlertList, 120000);
      return () => clearInterval(intervalId);
    }
  }, [
    auth,
    userData?.isPremiumUser,
    userData?.subscription?.SubscriptionPlan?.key,
  ]);

  const fetchAlertSettingsList = async (raceDetails) => {
    try {
      const { status, data } = await axiosInstance.get(`/raceAlert/getone`);
      if (status === 200) {
        const settingData = data?.data;
        const newData = raceDetails?.map((obj) => {
          return scheduleSoundAtSpecificTime(obj, settingData);
        });
      } else {
      }
    } catch (err) {}
  };

  const scheduleSoundAtSpecificTime = (raceData, settingData) => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let raceDate = moment(raceData?.Race?.startTimeDate).tz(timezone);
    const now = new Date();
    const alarm = new Date(raceDate);
    const getMin = settingData?.beforeTime / 60;
    alarm.setHours(alarm.getHours());
    alarm.setMinutes(alarm.getMinutes() - getMin);
    alarm.setSeconds(0);

    if (alarm > now) {
      const timeUntilAlarm = alarm - now;
      setTimeout(() => {
        if (settingData?.playSound) {
          const audio = new Audio(AudioManager);
          audio.play();
        }
        if (settingData?.raceAlert) {
          alert(`Your race "${raceData.Race?.track?.name}" is going to start`);
        }
      }, timeUntilAlarm);
    }
  };

  let checkUrl = window.location.pathname;
  let checkAuth =
    checkUrl == "/sign-in" ||
    checkUrl == "/sign-up" ||
    checkUrl == "/forgot-password" ||
    checkUrl == "/forgot-password-otp" ||
    checkUrl == "/verify-user";
  const checkCountry = async () => {
    try {
      setisLoading(true);
      const { status, data } = await axiosInstance.get(`public/check`);
      if (status === 200) {
        setcheckCountryCode(data?.data?.country_code);
        localStorage.setItem("countrycode", data?.data?.country_code);
        setisLoading(false);
      }
    } catch (err) {
      setcheckCountryCode("AU");
      setisLoading(false);
    }
  };
  const viewRoutes =
    checkAuth && !auth ? authRoutes : auth ? userRoutes : publicRoute;
  // const routing = useRoutes(
  //   checkCountryCode
  //     ? checkCountryCode == "AU"
  //       ? viewRoutes
  //       : RestrictedRoute
  //     : viewRoutes
  // );
  const routing = useRoutes(viewRoutes);

  const menuMessages = {
    en: { ...messagesEn },
    de: { ...messagesDe },
    // fr: { ...messagesFr },
    // pt: { ...messagesPt },
  };
  useEffect(() => {
    // checkCountry();
    generateSitemap();
    // sitemap();
    seturlData(sitemapurl());
    EventEmitter.subscribe("onapifailure", (data) => {
      dispatch(fetchRestrictedRoute(data));
      localStorage.removeItem("auth_token");
      localStorage.setItem("prev_authroute", window.location.pathname);
    });
    EventEmitter.subscribe("onissue", (data) => {
      if (data) {
        localStorage.setItem(
          "previous_page",
          window.location.pathname === "/issue-page"
            ? "/"
            : window.location?.search !== ""
              ? window.location.pathname + window.location?.search
              : window.location.pathname,
        );
        setTimeout(() => {
          data && navigate("/issue-page");
          // setTimeout(() => {
          //   localStorage.removeItem("firstLoadDone");
          // }, 5000);
        }, 1000);
      } else {
        // const prevPage = localStorage.getItem("previous_page");
        // if (prevPage) {
        //   setTimeout(() => {
        //     navigate(prevPage);
        //     localStorage.removeItem("previous_page");
        //   }, 1000);
        // }
      }
    });

    const restrictedPaths = [
      "/info",
      "sport-expert-tips",
      "/statistics",
      "odds-comparison",
      "/gettips",
    ];
    const IsPrivateRoute = userRoutes?.[0]?.children?.some((item) => {
      const isPrivatePath =
        item?.isPrivate &&
        (restrictedPaths?.some((path) => location.pathname.includes(path)) ||
          location.pathname.includes(item?.path));
      return isPrivatePath;
    });
    if (IsPrivateRoute && !localAuth) {
      if (window.location.pathname?.includes("odds-comparison")) {
        dispatch(fetchRestrictedRoute(true));
        localStorage.setItem("prev_authroute", window.location.pathname);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", location.pathname);
    }
  }, []);

  useEffect(() => {
    const handleMaintenanceUpdated = (data) => {
      if (data == "true") {
        localStorage.setItem(
          "previous_page",
          window.location.pathname === "/issue-page"
            ? "/"
            : window.location.pathname === "/maintenance.html"
              ? "/"
              : window.location?.search !== ""
                ? window.location.pathname + window.location?.search
                : window.location.pathname,
        );
        setTimeout(() => {
          window.location.href = "/maintenance.html";
        }, 0);
      }
    };

    EventEmitter.subscribe("onmaintenance", handleMaintenanceUpdated);

    return () => {
      EventEmitter.unsubscribe("onmaintenance", handleMaintenanceUpdated);
    };
  }, []);

  // useEffect(() => {
  //   const setViewport = () => {
  //     const scaleFactor = 1 / window.devicePixelRatio;
  //     const content = `width=device-width, initial-scale=${scaleFactor}`;
  //     document
  //       .querySelector("meta[name=viewport]")
  //       .setAttribute("content", content);
  //   };

  //   setViewport(); // Call the function once on component mount

  //   // Optionally, add an event listener for window resize to handle changes
  //   window.addEventListener("resize", setViewport);

  //   return () => {
  //     // Remove the event listener when component unmounts
  //     window.removeEventListener("resize", setViewport);
  //   };
  // }, []);

  // const sitemap = () => {
  //   const sitemapXml = generateSitemap();

  //   // Write the sitemap.xml file to the public folder
  //   fs.writeFile("public/sitemap.xml", sitemapXml, err => {
  //     if (err) {
  //       console.error("Error writing sitemap.xml", err);
  //     } else {
  //       console.log("Sitemap generated successfully!");
  //     }
  //   });
  // };
  useEffect(() => {
    // fetchThreeRacing(moment.tz(moment(), timezone).format("YYYY-MM-DD"));
    // fetchThreeRacing(
    //   moment
    //     .tz(moment(), timezone)
    //     .add(1, "days")
    //     .format("YYYY-MM-DD")
    // );
    // fetchThreeRacing(
    //   moment
    //     .tz(moment(), timezone)
    //     .add(2, "days")
    //     .format("YYYY-MM-DD")
    // );
    // fetchThreeRacing(
    //   moment
    //     .tz(moment(), timezone)
    //     .add(3, "days")
    //     .format("YYYY-MM-DD")
    // );
    // fetchThreeRacing(
    //   moment
    //     .tz(moment(), timezone)
    //     .add(4, "days")
    //     .format("YYYY-MM-DD")
    // );
    // fetchThreeRacing(
    //   moment
    //     .tz(moment(), timezone)
    //     .add(5, "days")
    //     .format("YYYY-MM-DD")
    // );
    // fetchThreeRacing(
    //   moment
    //     .tz(moment(), timezone)
    //     .add(6, "days")
    //     .format("YYYY-MM-DD")
    // );
    // fetchThreeRacing(
    //   moment
    //     .tz(moment(), timezone)
    //     .add(7, "days")
    //     .format("YYYY-MM-DD")
    // );
    // fetchThreeRacing(
    //   moment
    //     .tz(moment(), timezone)
    //     .add(8, "days")
    //     .format("YYYY-MM-DD")
    // );
    // fetchThreeRacing(
    //   moment
    //     .tz(moment(), timezone)
    //     .add(9, "days")
    //     .format("YYYY-MM-DD")
    // );
    // fetchCricketEvent();
    // fetchRLEvent();
    // fetchRUEvent();
    // fetchNBAEvent();
    // fetchAFLEvent();
    // fetchAREvent();
    // fetchTennisEvent();
    // fetchBaseballEvent();
    // fetchIceHockeyEvent();
    // fetchBoxingEvent();
    // fetchMMAEvent();
    // fetchSoccerEvent();
    // fetchGolfEvent();
  }, []);

  const checkRoute = () => {
    if (
      location.pathname.includes("/sign-up") ||
      location.pathname.includes("/sign-in") ||
      location.pathname.includes("/forgot-password") ||
      location.pathname.includes("/forgot-password-otp")
    ) {
      return false;
    } else {
      return true;
    }
  };
  function isUserLoggedIn() {
    // Return true if the user is logged in, otherwise return false

    return auth ? true : false;
  }

  useEffect(() => {
    if (Boolean(referralCode)) {
      localStorage.setItem("referralCode", referralCode);
      localStorage.setItem("referralType", referralType);
    }
    // Check if the user is not logged in and the popup hasn't been shown yet
    const isUserNotLoggedIn = !isUserLoggedIn();
    const hasPopupNotBeenShown = !localStorage.getItem("loginPopupShown");
    if (isUserNotLoggedIn && hasPopupNotBeenShown) {
      // Show the popup after 1 minute
      const timeoutId = setTimeout(() => {
        if (checkRoute()) {
          dispatch(fetchRestrictedRoute(true));
          localStorage.setItem("loginPopupShown", "true");
          localStorage.setItem("prev_authroute", window.location.pathname);
        }
      }, 60000); // 60000 milliseconds = 1 minute
      localStorage.setItem("popup_intervalId", timeoutId.toString());
      // Cleanup the timeout in case the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, []);

  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let urls = [];
  const fetchThreeRacing = async (date) => {
    try {
      const { status, data } = await axiosInstance.get(
        `v2/events/trackList/?todate=${date}&sportId=1,2,3&MeetingState=Aus/NZ,Intl&countryId=&stateId=&timezone=${timezone}`,
      );
      if (status === 200) {
        let urldata = data?.events?.map((item) => {
          let url = item?.race?.map((obj) => {
            let stirng = `<url><loc>https://smartb.com.au/racing/${
              item?.sportId === 1
                ? "horse"
                : item?.sportId === 2
                  ? "harness"
                  : "greyhounds"
            }/${item?.sportId}/${obj?.id}/${item?.trackId}/${
              item?.id
            }?date=${moment
              .utc(item?.eventToDate)
              .format("YYYY-MM-DD")}&amp;intl=${
              item?.track?.Country?.id === 13 ||
              item?.track?.Country?.id === 157
                ? false
                : true
            }</loc><lastmod>${moment
              .tz(moment(), timezone)
              .format(
                "YYYY-MM-DD",
              )}</lastmod><changefreq>daily</changefreq><priority>0.8</priority></url>`;
            return stirng;
          });
          return url;
        });

        let combineUrls = urls?.push(urldata?.flat()?.join(" "));
        setraceURLData(urls?.join(" "));
      }
    } catch (err) {}
  };
  const fetchCricketEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/crickets/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&CricketTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=5&liveOdd=true&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        let cricketUrlData = data?.result?.rows?.map((item) => {
          return `<url>
              <loc>https://smartb.com.au/teamsports/cricket/${
                item?.id
              }/true</loc>
              <lastmod>${moment
                .tz(moment(), timezone)
                .format("YYYY-MM-DD")}</lastmod>
              <changefreq>daily</changefreq>
              <priority>0.8</priority>
            </url>`;
        });
        setCricketData(cricketUrlData?.flat()?.join(" "));
        dispatch(fetchCricketData(data?.result?.rows));
      } else {
        dispatch(fetchCricketData([]));
      }
    } catch (err) {
      dispatch(fetchCricketData([]));
    }
  };
  const fetchRLEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/rls/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&RLTournamentId=&teamId=&timezone=${timezone}&SportId=12&oddCheck=true&marketId=1&limit=5&liveOdd=true&marketname=h2h&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        let RLUrlData = data?.result?.rows?.map((item) => {
          return `<url>
              <loc>https://smartb.com.au/teamsports/rugbyleague/${
                item?.id
              }/true</loc>
              <lastmod>${moment
                .tz(moment(), timezone)
                .format("YYYY-MM-DD")}</lastmod>
              <changefreq>daily</changefreq>
              <priority>0.8</priority>
            </url>`;
        });
        setRLData(RLUrlData?.flat()?.join(" "));
        dispatch(fetchRLData(data?.result?.rows));
      } else {
        dispatch(fetchRLData([]));
      }
    } catch (err) {
      dispatch(fetchRLData([]));
    }
  };
  const fetchRUEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/rls/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&RLTournamentId=&teamId=&timezone=${timezone}&SportId=13&oddCheck=true&marketId=1&limit=5&liveOdd=true&marketname=h2h&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        let RUUrlData = data?.result?.rows?.map((item) => {
          return `<url>
              <loc>https://smartb.com.au/teamsports/rugbyunion/${
                item?.id
              }/true</loc>
              <lastmod>${moment
                .tz(moment(), timezone)
                .format("YYYY-MM-DD")}</lastmod>
              <changefreq>daily</changefreq>
              <priority>0.8</priority>
            </url>`;
        });
        setRUData(RUUrlData?.flat()?.join(" "));
        dispatch(fetchRUData(data?.result?.rows));
      } else {
        dispatch(fetchRUData([]));
      }
    } catch (err) {
      dispatch(fetchRUData([]));
    }
  };
  const fetchNBAEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/nba/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&NBATournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=5&liveOdd=true&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        let NBAUrlData = data?.result?.rows?.map((item) => {
          return `<url>
              <loc>https://smartb.com.au/teamsports/basketball/${
                item?.id
              }/true</loc>
              <lastmod>${moment
                .tz(moment(), timezone)
                .format("YYYY-MM-DD")}</lastmod>
              <changefreq>daily</changefreq>
              <priority>0.8</priority>
            </url>`;
        });
        setNBAData(NBAUrlData?.flat()?.join(" "));
        dispatch(fetchBasketballData(data?.result?.rows));
      } else {
        dispatch(fetchBasketballData([]));
      }
    } catch (err) {
      dispatch(fetchBasketballData([]));
    }
  };
  const fetchAFLEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/afl/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&AFLTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=5&liveOdd=true&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        dispatch(fetchAFData(data?.result?.rows));
      } else {
        dispatch(fetchAFData([]));
      }
    } catch (err) {
      dispatch(fetchAFData([]));
    }
  };

  const fetchAREvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/ar/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&ARTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=5&liveOdd=true&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        dispatch(fetchARData(data?.result?.rows));
      } else {
        dispatch(fetchARData([]));
      }
    } catch (err) {
      dispatch(fetchARData([]));
    }
  };
  const fetchTennisEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/tennis/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&TennisTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=5&liveOdd=true&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        dispatch(fetchTennisData(data?.result?.rows));
      } else {
        dispatch(fetchTennisData([]));
      }
    } catch (err) {
      dispatch(fetchTennisData([]));
    }
  };
  const fetchBaseballEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/baseball/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&BaseballTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=5&liveOdd=true&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        dispatch(fetchBaseballData(data?.result?.rows));
      } else {
        dispatch(fetchBaseballData([]));
      }
    } catch (err) {
      dispatch(fetchBaseballData([]));
    }
  };
  const fetchGolfEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/golf/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&GolfTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&isOuright=true&limit=5&liveOdd=true&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        dispatch(fetchGolfData(data?.result?.rows));
      } else {
        dispatch(fetchGolfData([]));
      }
    } catch (err) {
      dispatch(fetchGolfData([]));
    }
  };
  const fetchIceHockeyEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/icehockey/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&IceHockeyTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=5&liveOdd=true&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        dispatch(fetchIceHockeyData(data?.result?.rows));
      } else {
        dispatch(fetchIceHockeyData([]));
      }
    } catch (err) {
      dispatch(fetchIceHockeyData([]));
    }
  };
  const fetchBoxingEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/boxing/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&BoxingTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=5&liveOdd=true&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        dispatch(fetchBoxingData(data?.result?.rows));
      } else {
        dispatch(fetchBoxingData([]));
      }
    } catch (err) {
      dispatch(fetchBoxingData([]));
    }
  };
  const fetchMMAEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/mma/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&MMATournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=5&liveOdd=true&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        dispatch(fetchMMAData(data?.result?.rows));
      } else {
        dispatch(fetchMMAData([]));
      }
    } catch (err) {
      dispatch(fetchMMAData([]));
    }
  };
  const fetchSoccerEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/soccer/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD",
          )}&endDate=&SoccerTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=5&liveOdd=true&isAdmin=false&isHome=true`,
      );
      if (status === 200) {
        dispatch(fetchSoccerData(data?.result?.rows));
      } else {
        dispatch(fetchSoccerData([]));
      }
    } catch (err) {
      dispatch(fetchSoccerData([]));
    }
  };
  useEffect(() => {
    document.getElementById("root").scrollIntoView();
  }, [checkUrl]);

  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState(menuMessages["en"]);

  const switchLanguage = (lang) => {
    setLocale(lang);
    setMessages(menuMessages[lang]);
  };
  const sitemapStartString = `<?xml version="1.0" encoding="UTF-8"?>
 <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">`;
  const sitemapEndString = `</urlset>`;
  const finalsitemap =
    sitemapStartString +
    urlData +
    raceURLData +
    cricketData +
    RLData +
    RUData +
    NBAData +
    sitemapEndString;
  return (
    <>
      {/* {isLoading ? (
      <div className="allsport-loader-center app-loader">
        <Loader />
      </div>
    ) : ( */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Context.Provider value={{ locale, switchLanguage, messages }}>
            <GlobalStyles />
            <TawkToScript />
            {routing}
          </Context.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
      {/* )} */}
    </>
  );
};

export default App;
export { Context as IntlContext };
