import { SPORTS_TYPE } from "../teamCommonComponent/newSportsUtils";
import PlayerTable from "./PlayerTable";
import { useParams } from "react-router";
import NRLStatsTable from "./NRLStatsTable";
import { usePlayerStats } from "src/helpers/context/PlayersStats/PlayerStatsContext";

const StatsLandingTable = ({ selectedStats }) => {
  const params = useParams();
  const sportsId = Number(params?.sportsId);
  const { playerStats, loading } = usePlayerStats() || {};

  return (
    <>
      {/* Cricket */}
      {sportsId === SPORTS_TYPE.circket && (
        <PlayerTable tableData={playerStats?.body ?? []} loading={loading} />
      )}

      {/* Rugby League */}
      {sportsId === SPORTS_TYPE.rugbyLeague && (
        <NRLStatsTable loading={false} selectedStats={selectedStats} />
      )}
    </>
  );
};

export default StatsLandingTable;
