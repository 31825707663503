import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Breadcrumbs, Tabs, Tab } from "@mui/material";
import _, { result } from "lodash";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import AdvertisingBannner from "../../advertisingBanner";
import AdBannner from "../../AdBanner/AdBanner";
import OurPartner from "src/views/component/ourPartners";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import OverviewDetails from "./overviewDetails";
import MatchDetails from "./matchDetails";
import TableDetails from "./tableDetails";
import "../leagueData/leagueData.scss";
import "./cricketSportData.scss";

const cricketTabData = [
  { value: 0, label: "Overview" },
  { value: 1, label: "Matches" },
  { value: 2, label: "Table" },
];

const CricketSportDataPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const tabsRef = useRef(null);
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [value, setValue] = useState(0);
  const [headerData, setHeaderData] = useState("");
  const [cricketTabData, setCricketTabData] = useState([
    { value: 0, label: "Overview" },
    { value: 1, label: "Matches" },
    { value: 2, label: "Table" },
  ]);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = (ReduxAdsData) => {
    let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const routeName =
    params?.sportId === "4"
      ? "cricket"
      : params?.sportId === "12"
        ? "rugbyleague"
        : params?.sportId === "13"
          ? "rugbyunion"
          : params?.sportId === "10"
            ? "basketball"
            : params?.sportId === "15"
              ? "americanfootball"
              : params?.sportId === "9"
                ? "australianrules"
                : params?.sportId === "16"
                  ? "golf"
                  : params?.sportId === "7"
                    ? "tennis"
                    : params?.sportId === "11"
                      ? "baseball"
                      : params?.sportId === "17"
                        ? "icehockey"
                        : params?.sportId === "6"
                          ? "boxing"
                          : params?.sportId === "5"
                            ? "mma"
                            : params?.sportId === "8"
                              ? "soccer"
                              : "";

  const handleChange = (id, value) => {
    setValue(id);
  };

  useEffect(() => {
    fetchBreacrumnData();
  }, [params]);

  const fetchBreacrumnData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/header?SportId=${params?.sportId}&tournamentId=${params?.tournamentId}`,
      );
      if (status === 200) {
        setHeaderData(data?.result);
      } else {
      }
    } catch (err) {}
  };
  const fetchTableTab = (upcomingTable, resultTable) => {
    const IsTableData =
      upcomingTable || resultTable
        ? cricketTabData
        : cricketTabData?.filter((item) => item?.label !== "Table");
    setCricketTabData(IsTableData);
  };
  const handleNavigate = (data) => {
    const tournamentSlug = data?.Scd ? data?.Scd : "tournament";
    const type = Number(params?.sportId) === 4 ? "cricket" : "";
    navigate(
      `/teamsports/${type}/statistics/${tournamentSlug}/info/${data?.SportId}/${data?.id}`,
    );
  };
  return (
    <>
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="league-data-wrap">
          <Box className="teamsport-data-header">
            <Box className="bredcrumn-deatils">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    Home
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to="#"
                    className="cursor-default"
                  >
                    Sports
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    // to={`/teamsports/${routeName}/odds/0/false`}
                    to={`/all-sports/${params?.type}/${params?.sportId}/odds/0/false?menu=fixtures_results&touranamentId=0&touranamentName=All+Leagues`}
                  >
                    {headerData?.sport?.sportName}
                  </Link>

                  <Typography>{headerData?.tournament?.name}</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Typography variant="h1">{headerData?.tournament?.name}</Typography>
          </Box>
          <Box className="teamsport-data-tab">
            <Tabs
              value={value}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              className="teamsport-data-tab-details"
              // disableRipple
              // disableFocusRipple
              id="news-tab"
              ref={tabsRef}
            >
              {cricketTabData?.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={item?.label}
                      value={item?.value}
                      onChange={() => handleChange(item?.value, item?.label)}
                      className={item?.value == value ? "active" : ""}
                    />
                  </Box>
                );
              })}
            </Tabs>
            {headerData?.tournamentList?.length > 0 ? (
              <Box className="tab-deatils">
                <Box className="quick-link-wrap">
                  <Typography className="details-text bold">
                    Quick Links:
                  </Typography>
                  <Typography className="details-text">
                    Seasons-
                    {headerData?.tournamentList?.map((item, index) => {
                      return (
                        <>
                          <span
                            key={index}
                            onClick={() => handleNavigate(item)}
                          >
                            {" "}
                            {item?.season}
                            {index !== headerData?.tournamentList.length - 1
                              ? ","
                              : ""}
                          </span>
                        </>
                      );
                    })}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Box className="cricket-data-wrap">
            {value === 0 ? (
              <OverviewDetails fetchTableTab={fetchTableTab} />
            ) : value === 1 ? (
              <MatchDetails />
            ) : (
              <TableDetails />
            )}
          </Box>
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default CricketSportDataPage;
