import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Reset } from "../../../assets/images/reset.svg";
import { IntlContext } from "src/App";
import AR from "src/assets/images/SportImages/australia-rules.png";
import AFL from "src/assets/images/SportImages/american-football.png";
import Tennis from "src/assets/images/SportImages/tennis.png";
import Soccer from "src/assets/images/SportImages/soccer.png";
import Baseball from "src/assets/images/SportImages/baseball.png";
import Basketball from "src/assets/images/SportImages/basketball.png";
import Boxing from "src/assets/images/SportImages/boxing.png";
import Cricket from "src/assets/images/SportImages/cricket.png";
import Golf from "src/assets/images/SportImages/golf.png";
import IceHockey from "src/assets/images/SportImages/ice-hokey.png";
import MMA from "src/assets/images/SportImages/mma.png";
import MotorSport from "src/assets/images/SportImages/motor-sport.png";
import RL from "src/assets/images/SportImages/rugby-league.png";
import RU from "src/assets/images/SportImages/rugby-union.png";
import moment from "moment-timezone";
import Select, { components } from "react-select";
import "./sportExpertTips.scss";
import _ from "lodash";
import OurPartner from "../ourPartners";
import NoDataComp from "../UI/NoData";
import Loader from "src/components/Loader";
import { Pagination } from "@mui/material";

const tipData = [
  {
    id: 0,
    name: "Expert Tips for Round 11",
    league: "National rugby league",
    time: "2021-02-28T17:41:57.000Z",
    sport: "rugbyleague",
  },
  {
    id: 1,
    name: "Expert Tips for Round 11",
    league: "National rugby league",
    time: "2021-02-28T17:41:57.000Z",
    sport: "cricket",
  },
  {
    id: 2,
    name: "Expert Tips for Round 11",
    league: "National rugby league",
    time: "2021-02-28T17:41:57.000Z",
    sport: "tennis",
  },
  {
    id: 3,
    name: "Expert Tips for Round 11",
    league: "National rugby league",
    time: "2021-02-28T17:41:57.000Z",
    sport: "soccer",
  },
  {
    id: 4,
    name: "Expert Tips for Round 11",
    league: "National rugby league",
    time: "2021-02-28T17:41:57.000Z",
    sport: "mma",
  },
];
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const SportExpertTipsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [expertTipData, setExpertTipData] = useState([]);
  const [expertTipDataCount, setExpertTipDataCount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const limit = 20;
  const rowPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [OrgAll, setOrgAll] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [teamApiCount, setTeamApiCount] = useState(0);

  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [countOrg, setcountOrg] = useState(0);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  let SportId =
    params?.type === "cricket"
      ? 4
      : params?.type === "rugbyleague"
        ? 12
        : params?.type === "rugbyunion"
          ? 13
          : params?.type === "basketball"
            ? 10
            : params?.type === "americanfootball"
              ? 15
              : params?.type === "australianrules"
                ? 9
                : params?.type === "baseball"
                  ? 11
                  : params?.type === "boxing"
                    ? 6
                    : params?.type === "icehockey"
                      ? 17
                      : params?.type === "mma"
                        ? 5
                        : params?.type === "soccer"
                          ? 8
                          : params?.type === "tennis"
                            ? 7
                            : params?.type === "golf"
                              ? 16
                              : "";

  useEffect(() => {
    setCurrentPage(1);
    fetchOrgData(currentPage, SportId, OrgAll);
    getAllTipData(1, null);
  }, [params]);

  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/tournament?SportId=${sID}&offset=${page}&limit=${limit}`,
      );
      if (status === 200) {
        setOrgApiCount(teamApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.name,
            value: item?.id,
          });
        });
        let filterData = _.unionBy(OrgAll, newdata);
        let allteamdatas = filterData?.unshift({
          label: "All Tournaments",
          value: 0,
        });
        setOrgAll(
          _.uniqBy(filterData, function (e) {
            return e.value;
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllTipData = async (page, tId) => {
    setLoading(true);

    try {
      const { status, data } = await axiosInstance.get(
        `/expertTips/web/getAllTips?SportId=${
          SportId ? SportId : ""
        }&limit=9&page=${page}&tournamentId=${
          tId ? tId : ""
        }&timezone=${timezone}`,
      );
      if (status === 200) {
        setLoading(false);
        setExpertTipData(data?.result?.rows);
        setExpertTipDataCount(data?.result?.count);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/tournament?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`,
      )
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.name,
              value: item?.id,
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function (e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };
  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, SportId);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, SportId, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };

  const getSportname = (type) => {
    return type === "cricket"
      ? "Cricket"
      : type === "rugbyleague"
        ? "Rugby League"
        : type === "rugbyunion"
          ? "Rugby Union"
          : type === "basketball"
            ? "Basketball "
            : type === "americanfootball"
              ? "American Football"
              : type === "australianrules"
                ? "Australian Rules "
                : type === "golf"
                  ? "Golf"
                  : type === "tennis"
                    ? "Tennis"
                    : type === "baseball"
                      ? "Baseball"
                      : type === "icehockey"
                        ? "Ice Hockey"
                        : type === "boxing"
                          ? "Boxing"
                          : type === "mma"
                            ? "mma"
                            : type === "soccer"
                              ? "soccer"
                              : "Rugby Union Sevens";
  };
  const getSportImage = (id) => {
    return id === 4
      ? Cricket
      : id === 12
        ? RL
        : id === 13
          ? RU
          : id === 10
            ? Basketball
            : id === 15
              ? AFL
              : id === 9
                ? AR
                : id === 16
                  ? Golf
                  : id === 7
                    ? Tennis
                    : id === 11
                      ? Baseball
                      : id === 17
                        ? IceHockey
                        : id === 6
                          ? Boxing
                          : id === 5
                            ? MMA
                            : Soccer;
    // type === "soccer"
    // ? Soccer
    // : "Rugby Union Sevens";
  };

  const handlePaginationClick = (event, page) => {
    setCurrentPage(Number(page));
    getAllTipData(Number(page), null);
  };

  const pageNumbers = [];

  if (expertTipDataCount > 0) {
    for (let i = 1; i <= Math.ceil(expertTipDataCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="sport-tips-wrap">
          <Box className="sport-tips-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  {/* {localesData?.MENU?.HOME} */}
                  HOME
                </Link>
                <Link underline="hover" color="inherit" to="/">
                  {/* {localesData?.MENU?.HOME} */}
                  SPORTS
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  to={
                    params?.type === "cricket"
                      ? `/all-sports/cricket/4/odds/0/false?menu=fixtures_results&touranamentId=0&touranamentName=All+Leagues`
                      : `/teamsports/${params?.type}/odds/0/false`
                  }
                >
                  {/* {localesData?.MENU?.HOME} */}
                  {getSportname(params?.type)}
                </Link>
                <Typography>Expert Tips</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">Expert Tips</Typography>
          </Box>
          <Box className="tab-search-section">
            <Box className="filter-section">
              <Select
                className="React sort-select"
                onMenuScrollToBottom={(e) => handleOnScrollBottomOrg(e)}
                onInputChange={(e) => handleOrgInputChange(0, e, SportId)}
                onChange={(e) => {
                  setSelectedOrg(e?.value);
                  // fetchOrgData(e?.value, selectedSports, OrgAll);
                  getAllTipData(1, e?.value);
                  setOrgApiCount(0);
                  setpageOrg(0);
                  setisOrgSelectOpen(false);
                  setCurrentPage(0);
                }}
                onFocus={() => setisOrgSelectOpen(true)}
                onBlur={() => setisOrgSelectOpen(false)}
                value={
                  selectedOrg &&
                  (isOrgSearch
                    ? searchOrg?.find((item) => {
                        return item?.value == selectedOrg;
                      })
                    : OrgAll?.find((item) => {
                        return item?.value == selectedOrg;
                      }))
                }
                options={isOrgSearch ? searchOrg : OrgAll}
                classNamePrefix="select"
                placeholder="All Tournaments"
                // isDisabled={
                //   selectedSports && selectedSports.length > 0 ? true : false
                // }
                components={{ DropdownIndicator }}
              />
            </Box>
          </Box>
          <Box
            className={
              expertTipData?.length > 0 ? "page-deatils-wrap" : "d-block"
            }
          >
            {isLoading ? (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            ) : expertTipData?.length > 0 ? (
              expertTipData?.map((item, index) => {
                return (
                  <>
                    <Box
                      className="tip-card-box"
                      onClick={() => {
                        const nameSlug = item?.tournamentName
                          ? item?.tournamentName
                              .trim()
                              .toLowerCase()
                              .replaceAll(" ", "-")
                          : "name";

                        navigate(
                          `/teamsports/${params?.type}/sport-expert-tips/${nameSlug}/${item?.round}/${item?.id}/${item?.tournamentId}`,
                        );
                      }}
                    >
                      <Box className="img-box">
                        <img
                          className="sport-img"
                          src={getSportImage(item?.Sport?.id)}
                        />
                      </Box>
                      <Box className="content-box">
                        <Box className="chip-sec">
                          <Typography className="chip-txt">
                            {item?.tournamentName}
                          </Typography>
                        </Box>
                        <Typography className="title">
                          Expert Tips for{" "}
                          {item?.displayName
                            ? item?.displayName
                            : (params?.type === "cricket" ? "Day" : "Round") +
                              " " +
                              item?.round}
                        </Typography>
                        <Typography className="time">
                          {item?.updatedAt &&
                            moment(item?.updatedAt).format("ddd")}{" "}
                          {item?.updatedAt &&
                            moment(item?.updatedAt).format("DD MMMM, YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                );
              })
            ) : (
              <>
                <Box style={{ border: "transparent" }}>
                  <Box
                    style={{
                      textAlign: "center",
                      border: "transparent",
                      fontFamily: "sans-serif",
                    }}
                  >
                    {/* No Data Available */}
                    <NoDataComp />
                  </Box>
                </Box>
              </>
            )}
          </Box>
          {!isLoading && expertTipData && expertTipData?.length > 0 ? (
            <Box className="pagination-section">
              <Pagination
                disabled={expertTipDataCount / 9 > 1 ? false : true}
                page={currentPage}
                onChange={(e, value) => handlePaginationClick(e, value)}
                count={pageNumbers[pageNumbers?.length - 1]}
                siblingCount={2}
                boundaryCount={1}
                size="small"
              />
            </Box>
          ) : (
            <></>
          )}

          <OurPartner />
        </Box>
      </Box>
    </>
  );
};

export default SportExpertTipsPage;
