import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import pageHeaderIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import AdBannner from "../../AdBanner/AdBanner";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import { Config } from "src/helpers/context";
import OurPartner from "../../ourPartners";
import TournamentTeamDropdown from "../../allsports/teamSpoorts/allTeamSports/TournamentTeamDropdown";
import TeamSportNews from "../../allsports/teamSpoorts/allTeamSports/TeamSportNews";
import "./newsLanding.scss";
import SportsHeader from "../teamCommonComponent/sportsHeader";
import ViewExpertTips from "../teamCommonComponent/viewExpertTips";

const NewsLanding = () => {
  const params = useParams();
  const location = useLocation();
  const teamSportType = params?.sportsTypeName;
  const searchParamsMenu = new URLSearchParams(location.search);
  const urlTouranamentId = searchParamsMenu.get("touranamentId");
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const expertTipsCount = useSelector(
    (state) => state?.reduxData?.viewExpertTipsCount,
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [selectedOrgLabel, setSelectedOrgLabel] = useState("");
  const [selectedTeamsLabel, setSelectedTeamsLabel] = useState("");

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config.release === "IN" ? pageHeaderIndia : banner,
          )}
        </Box>
        <Box>
          <SportsHeader
            localesData={localesData}
            params={params}
            expertTipsCount={expertTipsCount}
            screenWidth={screenWidth}
          />
        </Box>
        <Box className="homepage-landing-page-wrap news-landing-page-wrap">
          <Box className="homepage-landing-header">
            <Box className="news-team-select-wrap">
              <TournamentTeamDropdown
                teamSportType={teamSportType}
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg}
                selectedTeams={selectedTeams}
                setSelectedTeams={setSelectedTeams}
                selectedOrgLabel={selectedOrgLabel}
                setSelectedOrgLabel={setSelectedOrgLabel}
                selectedTeamsLabel={selectedTeamsLabel}
                setSelectedTeamsLabel={setSelectedTeamsLabel}
              />
            </Box>
            {screenWidth >= 1023 && expertTipsCount > 0 && (
              <Box>
                <ViewExpertTips />
              </Box>
            )}
          </Box>
          <Box>
            <TeamSportNews
              teamSportType={teamSportType}
              newsType={"sport"}
              selectedOrg={selectedOrg}
              selectedTeams={selectedTeams}
              selectedOrgLabel={selectedOrgLabel}
              selectedTeamsLabel={selectedTeamsLabel}
              landingPage="sport-landing-page"
            />
          </Box>
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default NewsLanding;
