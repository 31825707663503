import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Tooltip } from "@mui/material";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import { ReactComponent as AmericanFootball } from "../../../../../../assets/images/SportIcon/blueSportIcon/AmericanFootball.svg";
import { ReactComponent as AustralianRules } from "../../../../../../assets/images/SportIcon/blueSportIcon/AustralianRules.svg";
import { ReactComponent as Baseball } from "../../../../../../assets/images/SportIcon/blueSportIcon/Baseball.svg";
import { ReactComponent as Basketball } from "../../../../../../assets/images/SportIcon/blueSportIcon/Basketball.svg";
import { ReactComponent as Boxing } from "../../../../../../assets/images/SportIcon/blueSportIcon/Boxing.svg";
import { ReactComponent as Cricket } from "../../../../../../assets/images/SportIcon/blueSportIcon/Cricket.svg";
import { ReactComponent as Golf } from "../../../../../../assets/images/SportIcon/blueSportIcon/Golf.svg";
import { ReactComponent as IceHockey } from "../../../../../../assets/images/SportIcon/blueSportIcon/IceHockey.svg";
import { ReactComponent as MixedMartialArts } from "../../../../../../assets/images/SportIcon/blueSportIcon/MixedMartialArts.svg";
import { ReactComponent as RugbyLeague } from "../../../../../../assets/images/SportIcon/blueSportIcon/RugbyLeague.svg";
import { ReactComponent as RugbyUnion } from "../../../../../../assets/images/SportIcon/blueSportIcon/RugbyUnion.svg";
import { ReactComponent as Soccer } from "../../../../../../assets/images/SportIcon/blueSportIcon/Soccer.svg";
import { ReactComponent as Tennis } from "../../../../../../assets/images/SportIcon/blueSportIcon/Tennis.svg";
import { ReactComponent as Batting } from "../../../../../../assets/images/SportIcon/bat.svg";
import { ReactComponent as Bowling } from "../../../../../../assets/images/SportIcon/ball.svg";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import axiosInstance from "../../../../../../helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "src/components/Loader";
import { Config } from "src/helpers/context";
import DefaultImg from "src/assets/images/smartb_default.png";
import { fetchFromStorage } from "src/helpers/context";
import { toast } from "react-toastify";
import { ReactComponent as WinArrow } from "../../../../../../assets/images/icons/redWinArrow.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";

const SportCard = ({ isSliderLoading, selectedSportData, type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setApiInfo } = useAutoSaveStore();
  const BetslipData = useSelector((state) => state?.reduxData?.BetSlipData);

  const BookkeeperData = useSelector(
    (state) => state?.reduxData?.BookkeeperData,
  );

  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    fulldata,
    IsBetslip,
    Isscore,
    OddsType,
    team,
  ) => {
    const betOfferId =
      team === "hometeam" ? fulldata?.homeOddId : fulldata?.awayOddId;
    const teamId =
      team === "hometeam" ? fulldata?.homeTeamId : fulldata?.awayTeamId;
    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    let isAdded = BetslipData?.some((el) => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == betOfferId &&
        el?.eventId == fulldata?.eventId
      )
        return true;
      else return false;
    });

    return (
      <>
        {odds || odds == 0 ? (
          <span
            className={`odds-btn cursor-pointer current-best-odds-value  ${
              isAdded && IsBetslip === "betslip" ? "betslip-added" : ""
            } `}
            onClick={(e) => {
              IsBetslip === "nobetslip"
                ? handleBookkeeperCounter(BookKeeperId, type, e)
                : isAdded
                  ? handleDeleteBetslip(BookKeeperId, betOfferId, fulldata, e)
                  : handleAddToBetSlip(
                      BookKeeperId,
                      type,
                      teamId,
                      fulldata,
                      Isscore,
                      betOfferId,
                      e,
                    );
            }}
          >
            <span className="odds-link">
              {IsBetslip === "betslip" && !Isscore ? (
                <Tooltip title="Bet" className="bet-tooltip" placement="top">
                  <span className="bet-now">
                    {" "}
                    {odds == 0 ? "SP" : Number(odds).toFixed(2)}{" "}
                  </span>
                </Tooltip>
              ) : (
                <>{odds == 0 ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </span>
          </span>
        ) : null}
      </>
    );
  };
  const fetchCurrentBestOdds = (data, team) => {
    let Isscore =
      type === "result"
        ? true
        : data?.status === "inprogress" || data?.ScoreBoard?.Epr === 1;
    const oddNum = team === "hometeam" ? data?.homeOdd : data?.awayOdd;
    const oddsBookkeeperId =
      team === "hometeam" ? data?.homeBookkeeperId : data?.awayBookkeeperId;

    if (oddNum) {
      return (
        <Box className="current-best-odds-wrap">
          {fetchClickableOdds(
            oddNum,
            oddsBookkeeperId,
            "header",
            data,
            "betslip",
            Isscore,
            "currentbest",
            team,
          )}
          {/* <span className="current-best-odds-value">
              {maxno ? maxno : "NOA"}
            </span> */}
          <Box className="current-best-odds-icon">
            {oddsicon(oddsBookkeeperId, "header", data, team)}
          </Box>
        </Box>
      );
    } else {
      return "";
    }
  };
  const oddsicon = (BookKeeperId, type, fullData, team) => {
    const identifierUrl =
      team === "hometeam"
        ? fullData?.homeBookkeeperUrl
        : fullData?.awayBookkeeperUrl;
    const oddsVal = team === "hometeam" ? fullData?.homeOdd : fullData?.awayOdd;

    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    let imageUrl = iconData?.currentBest_logo;

    if (BookKeeperId === 8) {
      // const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

      // const Odds = Bet365Data?.filter(
      //   ele => ele?.providerMarketId && ele?.providerParticipantId
      // );
      const urlLink =
        fullData?.providerMarketId && fullData?.providerParticipantId
          ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${fullData?.providerMarketId}-${fullData?.providerParticipantId}~${oddsVal}~1&bet=1`
          : `${iconData?.affiliate_link}`;
      return (
        <Box
          // href={
          //
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="current-best-odds-icon"
          // onClick={e => handleAnchorTagClick(e, urlLink)}
        >
          <LazyLoadImage
            className="currentbest-bookmaker-thumb"
            src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
            onClick={(e) => {
              handleBookkeeperCounter(BookKeeperId, type, e);
              handleAnchorTagClick(e, urlLink);
            }}
            alt="Odds Icon"
          />
        </Box>
      );
    } else {
      const urlLink = identifierUrl
        ? identifierUrl + `?Referrer=SmartB`
        : iconData?.affiliate_link;
      return (
        <Box
          // href={
          //   filteredData?.length > 0 && filteredData?.[0]?.url
          //     ? filteredData?.[0]?.url + `?Referrer=SmartB`
          //     : iconData?.affiliate_link
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="current-best-odds-icon"
          // onClick={e => handleAnchorTagClick(e, urlLink)}
        >
          <LazyLoadImage
            className="currentbest-bookmaker-thumb"
            src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
            onClick={(e) => {
              handleBookkeeperCounter(BookKeeperId, type, e);
              handleAnchorTagClick(e, urlLink);
            }}
            alt="Odds Icon"
          />
        </Box>
      );
    }
  };
  const handleBookkeeperCounter = async (BookKeeperId, type, e, SportId) => {
    e.stopPropagation();

    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(SportId),
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload,
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const handleAnchorTagClick = (e, url) => {
    window.open(url, "_blank");
    e.stopPropagation();
  };

  const handleNavigate = (data) => {
    let sportName =
      data?.SportId === 4
        ? "cricket"
        : data?.SportId === 10
          ? "basketball"
          : data?.SportId === 15
            ? "americanfootball"
            : data?.SportId === 9
              ? "australianrules"
              : data?.SportId === 16
                ? "golf"
                : data?.SportId === 7
                  ? "tennis"
                  : data?.SportId === 11
                    ? "baseball"
                    : data?.SportId === 17
                      ? "icehockey"
                      : data?.SportId === 6
                        ? "boxing"
                        : data?.SportId === 5
                          ? "mma"
                          : data?.SportId === 8
                            ? "soccer"
                            : data?.SportId === 12
                              ? "rugbyleague"
                              : data?.SportId === 13
                                ? "rugbyunion"
                                : "rugbyunionsevens";
    // if (Config?.release == "IN") {
    //   navigate(
    //     data?.SportId === 16
    //       ? `/teamsports/golf/outrights/0/false`
    //       : `/all-sports/${sportName}/${data?.SportId}/odds/0/false?menu=fixtures_results&touranamentId=0&touranamentName=All+Leagues`,
    //   );
    // } else {
    //   navigate(
    //     data?.SportId === 16
    //       ? `/teamsports/golf/outrights/${data?.eventId}/true`
    //       : `/all-sports/${sportName}/${data?.SportId}/odds/${data?.eventId}/true?menu=fixtures_results&touranamentId=0&touranamentName=All+Leagues`,
    //   );
    // }

    if (Config?.release == "IN") {
      navigate(
        data?.SportId === 16
          ? `/teamsports/golf/outrights/0/false`
          : `/teamsports/${sportName}/odds/0/false`,
      );
    } else {
      if (data?.SportId === 4) {
        navigate(
          data?.SportId === 16
            ? `/teamsports/golf/outrights/${data?.eventId}/true`
            : `/all-sports/${sportName}/${data?.SportId}/odds/${data?.eventId}/true?menu=fixtures_results&touranamentId=0&touranamentName=All+Leagues`,
        );
      } else {
        navigate(
          data?.SportId === 16
            ? `/teamsports/golf/outrights/${data?.eventId}/true`
            : `/teamsports/${sportName}/odds/${data?.eventId}/true`,
        );
      }
    }
  };

  const handleAddToBetSlip = async (
    BookKeeperId,
    type,
    teamId,
    fulldata,
    Isscore,
    betOfferId,
    e,
  ) => {
    e.stopPropagation();
    handleBookkeeperCounter(BookKeeperId, type, e, fulldata?.SportId);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    let payload = {
      sportId: fulldata?.SportId,
      eventId: fulldata?.eventId,
      teamId: teamId,
      bookKeeperId: BookKeeperId,
      betOfferId: betOfferId,
    };
    if (isLogin) {
      if (!Isscore) {
        try {
          const { status, data } = await axiosInstance.post(
            `betSlipCard/BetSlipCard`,
            payload,
          );
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });

            const addedData = [...BetslipData, data?.result];
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {
          }
        } catch (err) {
          console.log("error", err);
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
      if (!Isscore) {
        setApiInfo({
          api: `betSlipCard/BetSlipCard`,
          payload: payload,
          method: "post",
        });
      }
    }
  };
  const handleDeleteBetslip = async (BookKeeperId, oddId, fulldata, e) => {
    e.stopPropagation();
    let delslip = BetslipData?.filter((el) => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddId &&
        el?.eventId == fulldata?.eventId
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`,
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        let chageData = BetslipData?.filter((obj) => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };

  const fetchTeamlogo = (item, type) => {
    const getTeamFlag = (flag) => {
      if (!flag)
        return (
          <LazyLoadImage
            className="team-img"
            src={DefaultTeam}
            alt="Odds Icon"
          />
        );

      if (flag.includes("uploads")) {
        return (
          <LazyLoadImage
            src={Config?.mediaURL + flag}
            className="team-img"
            alt="team Icon"
            onError={(e) => {
              e.target.src = DefaultTeam;
            }}
          />
        );
      }

      return <LazyLoadImage src={flag} className="team-img" alt="team Icon" />;
    };

    if (type === "hometeam") {
      return getTeamFlag(item?.homeTeamFlag);
    } else {
      return getTeamFlag(item?.awayTeamFlag);
    }
  };

  const getTeamScores = (data, teamId, teamType) => {
    const teamDataScore =
      teamType === "hometeam" ? data?.homeScore : data?.awayScore;
    const scores = teamDataScore?.current;
    return (
      <>
        {data?.SportId === 4 ? (
          <>
            {teamType === "hometeam" ? (
              data?.Epr == 1 && data?.Tr1C1 == null && data?.Ebat == 2 ? (
                <Typography className="team-score">Yet to bat</Typography>
              ) : data?.Exd > "1" ? (
                <Typography className="team-score">
                  {data?.Tr1C1 ?? ""}
                  {data?.Tr1CW1 && data?.Tr1CW1 === 10
                    ? ""
                    : (data?.Tr1CW1 || data?.Tr1CW1 === 0) &&
                      `/${data?.Tr1CW1}${
                        data?.Tr1CD1 && data?.Tr1CD1 === 1 ? "d" : ""
                      }`}{" "}
                  {(data?.Tr1C2 || data?.Tr1C2 === 0) && `& ${data?.Tr1C2}`}
                  {data?.Tr1CW2 && data?.Tr1CW2 === 10
                    ? ""
                    : (data?.Tr1CW2 || data?.Tr1CW2 === 0) &&
                      `/${data?.Tr1CW2}${
                        data?.Tr1CD2 && data?.Tr1CD2 === 1 ? "d" : ""
                      }`}{" "}
                </Typography>
              ) : (
                <Typography className="team-score">
                  {data?.Tr1C1 ?? ""}
                  {data?.Tr1CW1 && data?.Tr1CW1 === 10
                    ? ""
                    : (data?.Tr1CW1 || data?.Tr1CW1 === 0) &&
                      `/ ${data?.Tr1CW1}`}{" "}
                  <span> {data?.Tr1CO1 ? `(${data?.Tr1CO1})` : ""}</span>{" "}
                </Typography>
              )
            ) : data?.Epr == 1 && data?.Tr2C1 == null && data?.Ebat == 1 ? (
              <Typography className="team-score">Yet to bat</Typography>
            ) : data?.Exd > "1" ? (
              <Typography className="team-score">
                {data?.Tr2C1 ?? ""}
                {data?.Tr2CW1 && data?.Tr2CW1 === 10
                  ? ""
                  : (data?.Tr2CW1 || data?.Tr2CW1 === 0) &&
                    `/${data?.Tr2CW1}${
                      data?.Tr2CD1 && data?.Tr2CD1 === 1 ? "d" : ""
                    }`}{" "}
                {(data?.Tr2C2 || data?.Tr2C2 === 0) && `& ${data?.Tr2C2}`}
                {data?.Tr2CW2 && data?.Tr2CW2 === 10
                  ? ""
                  : (data?.Tr2CW2 || data?.Tr2CW2 === 0) &&
                    `/${data?.Tr2CW2}${
                      data?.Tr2CD2 && data?.Tr2CD2 === 1 ? "d" : ""
                    }`}{" "}
              </Typography>
            ) : (
              <Typography className="team-score">
                {data?.Tr2C1 ?? ""}
                {data?.Tr2CW1 && data?.Tr2CW1 === 10
                  ? ""
                  : (data?.Tr2CW1 || data?.Tr2CW1 === 0) &&
                    `/ ${data?.Tr2CW1}`}{" "}
                <span>{data?.Tr2CO1 ? `(${data?.Tr2CO1})` : ""}</span>
              </Typography>
            )}
          </>
        ) : data?.SportId === 8 ? (
          <>
            {teamType === "hometeam" ? (
              <span>{data?.Tr1 ?? "-"}</span>
            ) : (
              <sapn>{data?.Tr2 ?? "-"}</sapn>
            )}{" "}
          </>
        ) : (
          <span className="team-score">{scores}</span>
        )}
      </>
    );
  };

  const fetchTournamentlogo = (item) => {
    if (item?.GolfTournament) {
      let TeamLogo = item?.GolfTournament?.flag?.includes("uploads") ? (
        <LazyLoadImage
          src={Config.mediaURL + item?.GolfTournament?.flag}
          className="team-img"
          alt="team Icon"
          placeholder={
            <div className="allsport-loader-center news-loader">
              <Loader />
            </div>
          }
        />
      ) : item?.GolfTournament?.flag ? (
        <LazyLoadImage
          src={item?.GolfTournament?.flag}
          className="team-img"
          alt="team Icon"
          placeholder={
            <div className="allsport-loader-center news-loader">
              <Loader />
            </div>
          }
        />
      ) : (
        <LazyLoadImage
          className="team-img"
          src={DefaultTeam}
          alt="Odds Icon"
          placeholder={
            <div className="allsport-loader-center news-loader">
              <Loader />
            </div>
          }
        />
      );
      return TeamLogo;
    } else {
      return (
        <LazyLoadImage
          className="team-img"
          src={DefaultTeam}
          alt="Odds Icon"
          placeholder={
            <div className="allsport-loader-center news-loader">
              <Loader />
            </div>
          }
        />
      );
    }
  };

  const fetchTournamentName = (item) => {
    let tournamentName =
      item?.SportId == 4
        ? item?.CricketTournament?.name
        : item?.SportId == 12 || item?.SportId == 13 || item?.SportId == 14
          ? item?.RLTournament?.name
          : item?.SportId == 15
            ? item?.AFLTournament?.name
            : item?.SportId == 9
              ? item?.ARTournament?.name
              : item?.SportId == 16
                ? item?.GolfTournament?.name
                : item?.SportId == 7
                  ? item?.TennisTournament?.name
                  : item?.SportId == 11
                    ? item?.BaseballTournament?.name
                    : item?.SportId == 17
                      ? item?.IceHockeyTournament?.name
                      : item?.SportId == 6
                        ? item?.BoxingTournament?.name
                        : item?.SportId == 5
                          ? item?.MMATournament?.name
                          : item?.SportId == 8
                            ? item?.SoccerTournament?.name
                            : item?.SportId == 10
                              ? item?.NBATournament?.NBACategory === null
                                ? item?.NBATournament?.name
                                : item?.NBATournament?.name +
                                  " " +
                                  item?.NBATournament?.NBACategory?.name
                              : "";
    return (
      <>
        {tournamentName && (
          <span className="tournament-name">{tournamentName}</span>
        )}
      </>
    );
  };
  return (
    <>
      {isSliderLoading ? (
        <div className="allsport-loader-center news-loader">
          <Loader />
        </div>
      ) : selectedSportData?.length > 0 ? (
        <Box
          className={
            selectedSportData?.length === 1
              ? "sport-slider-wrap single-slide"
              : "sport-slider-wrap"
          }
        >
          <Box>
            {selectedSportData?.map((item) => {
              return (
                <>
                  <Box
                    className={
                      type === "result"
                        ? "racing-slider-details result-detail"
                        : "racing-slider-details sport-noresult-slider-details"
                    }
                    // onClick={() => {
                    //   handleNavigate(item);
                    // }}
                  >
                    <Box
                      className={`teamdetail-odds-wrap  ${
                        ((item?.status === "inprogress" ||
                          item?.status === "finished") &&
                          item?.homeScore) ||
                        item?.Epr === 1 ||
                        item?.Epr === 2
                          ? ""
                          : "no-result-odds-wrap"
                      }`}
                    >
                      <Box className="icon-odds-wrap">
                        <Box className="icon-wrap">
                          {fetchTeamlogo(item, "hometeam")}
                        </Box>
                        {fetchCurrentBestOdds(item, "hometeam")}
                      </Box>
                      <Box className="team-details-container">
                        <Box className="tournament-indicator">
                          <span className="tournament-name">
                            {item?.TournamentName}
                          </span>
                        </Box>
                        <Box>
                          <Tooltip
                            title={item?.eventName}
                            placement="bottom"
                            arrow
                          >
                            <span className="event-name">
                              {item?.eventName}
                            </span>
                          </Tooltip>
                        </Box>
                        <Box className="date-time">
                          {" "}
                          {moment
                            .utc(item?.startTime)
                            .local()
                            .format("DD/MM/YYYY | hh:mm A")}
                        </Box>

                        <Box
                          className="compare-odds cursor-pointer"
                          onClick={() => {
                            handleNavigate(item);
                          }}
                        >
                          Compare odds
                        </Box>
                      </Box>

                      <Box className="icon-odds-wrap">
                        <Box className="icon-wrap">
                          {fetchTeamlogo(item, "awayteam")}
                        </Box>
                        {fetchCurrentBestOdds(item, "awayteam")}
                      </Box>
                    </Box>

                    {((item?.status === "inprogress" ||
                      item?.status === "finished") &&
                      item?.awayScore) ||
                    item?.Epr === 1 ||
                    item?.Epr === 2 ? (
                      <Box className="result-wrap">
                        <Box className="featured-sport-score">
                          <Box className="score-details">
                            <span className="score">
                              <span className="win-icon">
                                {item?.winnerCode ? (
                                  item?.winnerCode === 1 ? (
                                    <WinArrow />
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </span>
                              {getTeamScores(
                                item,
                                item?.homeTeamId,
                                "hometeam",
                              )}
                            </span>
                          </Box>
                          <Box>-</Box>
                          <Box className="score-details">
                            <span className="score">
                              <span className="win-icon">
                                {item?.winnerCode ? (
                                  item?.winnerCode === 2 ? (
                                    <WinArrow />
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </span>
                              {getTeamScores(
                                item,
                                item?.awayTeamId,
                                "awayteam",
                              )}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </>
              );
            })}
          </Box>
        </Box>
      ) : (
        <Box className="nodata-slider f-16">No Data Available</Box>
      )}
    </>
  );
};

export default SportCard;
