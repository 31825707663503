import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Select from "react-select";
import { ReactComponent as LeftArrow } from "src/assets/images/left-arrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { useLadder } from "src/helpers/context/Ladder/LadderContext";

const StepperComponent = () => {
  const { roundList, round, setRound } = useLadder();
  const newRoundDataOption = roundList.map((item) => ({
    value: item?.round,
    label: `Round ${item?.round}`,
  }));

  const handleSelectChange = (selectedOption) => {
    setRound(selectedOption?.value);
  };

  return (
    <Box className="stepper-score-sec">
      <Box className="stepper-input">
        <Button
          className="stepper-input__button"
          onClick={() => {
            const index = newRoundDataOption.findIndex(
              (item) => item.value === round,
            );
            if (index > 0) {
              setRound(newRoundDataOption[index - 1].value);
            }
          }}
          disabled={round === newRoundDataOption[0]?.value}
          startIcon={<LeftArrow />}
        ></Button>

        <Select
          value={newRoundDataOption.find((item) => item.value === round)}
          onChange={handleSelectChange}
          options={newRoundDataOption}
          className="React tipping-select"
          classNamePrefix="select"
          isSearchable={false}
        />

        <Button
          className="stepper-input__button Next-btn"
          onClick={() => {
            const index = newRoundDataOption.findIndex(
              (item) => item.value === round,
            );
            if (index < newRoundDataOption.length - 1) {
              setRound(newRoundDataOption[index + 1].value);
            }
          }}
          disabled={
            round === newRoundDataOption[newRoundDataOption.length - 1]?.value
          }
          endIcon={<RightArrow />}
        ></Button>
      </Box>
    </Box>
  );
};

export default StepperComponent;
