import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Tooltip } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import RaceGreyhounds from "../../../assets/images/Sport/races_greyhounds.svg";
import { ReactComponent as AmericanFootball } from "../../../assets/images/SportIcon/blueSportIcon/AmericanFootball.svg";
import { ReactComponent as AustralianRules } from "../../../assets/images/SportIcon/blueSportIcon/AustralianRules.svg";
import { ReactComponent as Baseball } from "../../../assets/images/SportIcon/blueSportIcon/Baseball.svg";
import { ReactComponent as Basketball } from "../../../assets/images/SportIcon/blueSportIcon/Basketball.svg";
import { ReactComponent as Boxing } from "../../../assets/images/SportIcon/blueSportIcon/Boxing.svg";
import { ReactComponent as Cricket } from "../../../assets/images/SportIcon/blueSportIcon/Cricket.svg";
import { ReactComponent as Golf } from "../../../assets/images/SportIcon/blueSportIcon/Golf.svg";
import { ReactComponent as IceHockey } from "../../../assets/images/SportIcon/blueSportIcon/IceHockey.svg";
import { ReactComponent as MixedMartialArts } from "../../../assets/images/SportIcon/blueSportIcon/MixedMartialArts.svg";
import { ReactComponent as RugbyLeague } from "../../../assets/images/SportIcon/blueSportIcon/RugbyLeague.svg";
import { ReactComponent as RugbyUnion } from "../../../assets/images/SportIcon/blueSportIcon/RugbyUnion.svg";
import { ReactComponent as Soccer } from "../../../assets/images/SportIcon/blueSportIcon/Soccer.svg";
import { ReactComponent as Tennis } from "../../../assets/images/SportIcon/blueSportIcon/Tennis.svg";
import { ReactComponent as Batting } from "../../../assets/images/SportIcon/bat.svg";
import { ReactComponent as Bowling } from "../../../assets/images/SportIcon/ball.svg";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { IntlContext } from "src/App";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axiosInstance from "../../../helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "src/components/Loader";
import { Config } from "src/helpers/context";
import { release } from "src/helpers/context/release";
import DefaultImg from "src/assets/images/smartb_default.png";
import { fetchFromStorage } from "src/helpers/context";
import { toast } from "react-toastify";
import CustomTab from "./HomeComponents/CustomTab";
import axios from "axios";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";

const FeaturedSportV2 = ({ data, availableSport }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const abortControllerRef = useRef(null);
  const { setApiInfo } = useAutoSaveStore();
  const BetslipData = useSelector((state) => state?.reduxData?.BetSlipData);
  const sportData = useSelector((state) => state?.reduxData?.SportData);
  const layoutData = useSelector((state) => state?.reduxData?.LayoutData);
  const BookkeeperData = useSelector(
    (state) => state?.reduxData?.BookkeeperData,
  );
  const localAuth = fetchFromStorage("auth_token");
  let isLogin = localAuth ? true : false;
  const [featureSportData, setFeatureSportData] = useState([]);
  const [featureSportLoader, setFeatureSportLoader] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [tabvalue, setTabValue] = useState(0);
  const [selectedSport, setSelectedSport] = useState(0);
  const [allSportData, setAllSportData] = useState([]);

  const maxDots = 10;

  const settingsSport = {
    infinite: featureSportData?.length > 2,
    speed: 500,
    slidesToShow:
      featureSportData?.length > 2 ? 3 : featureSportData?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    focusOnSelect: false,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    dots: false,
    arrows: featureSportData?.length > 2,
    // adaptiveHeight: true,
    beforeChange: (current, next) => {
      handleBeforeChange();
    },
    prevArrow: (
      <Box className="slick-prev">
        <ChevronLeftIcon />
      </Box>
    ),
    nextArrow: (
      <Box className="slick-next">
        <ChevronRightIcon />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: featureSportData?.length > 1 ? 2 : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 799,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // if (featureSportData?.length > maxDots) {
  //   settingsSport.dots = false; // If you have more than 10 slides, hide the dots
  // }

  useEffect(() => {
    // Cleanup on component unmount
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (data) {
      fetchFeaturedSport();
    }
  }, [data]);
  useEffect(() => {
    if (availableSport && availableSport?.length > 0) {
      const teamSportData = availableSport
        ?.filter((item) => item?.sportTypeId == 2)
        ?.sort((a, b) => a?.sportName.localeCompare(b?.sportName))
        ?.filter((item) => item?.sportFeatured === true);
      const selectedIds = [];
      const fetchIds = teamSportData?.map((item) =>
        selectedIds?.push(item?.id),
      );
      const newData = [];
      const addAll = (teamSportData || [])?.unshift({
        sportName: "All",
        id: selectedIds?.length > 0 ? selectedIds.toString() : 0,
      });
      let sportList = teamSportData?.map((item, index) => {
        newData.push({
          label: item?.sportName,
          value: index,
          name: item?.sportName,
          id: item?.id,
        });
      });
      setAllSportData(newData);
      if (isLogin) {
        if (
          (layoutData && Object.entries(layoutData)?.length > 0) ||
          layoutData === null
        ) {
          if (newData?.[0]?.id || newData?.[0]?.id === 0) {
            setSelectedSport(newData?.[0]?.id);
            fetchFeaturedSport(newData?.[0]?.id);
          }
        }
      } else {
        if (newData?.[0]?.id || newData?.[0]?.id === 0) {
          setSelectedSport(newData?.[0]?.id);
          fetchFeaturedSport(newData?.[0]?.id);
        }
      }
    }
  }, [availableSport, layoutData]);

  const handleTabChange = (event, item) => {
    setTabValue(item?.value);
    setSelectedSport(item?.id);
    fetchFeaturedSport(item?.id);
    setFeatureSportData([]);
  };

  const fetchFeaturedSport = async (homeSportId) => {
    setFeatureSportLoader(true);
    // Cancel the previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController instance
    const newAbortController = new AbortController();
    abortControllerRef.current = newAbortController;

    let SportId =
      data?.sportsTypeName === "cricket"
        ? 4
        : data?.type === "rugbyleague"
          ? 12
          : data?.type === "rugbyunion"
            ? 13
            : data?.type === "basketball"
              ? 10
              : data?.type === "americanfootball"
                ? 15
                : data?.type === "australianrules"
                  ? 9
                  : data?.type === "baseball"
                    ? 11
                    : data?.type === "boxing"
                      ? 6
                      : data?.type === "icehockey"
                        ? 17
                        : data?.type === "mma"
                          ? 5
                          : data?.type === "soccer"
                            ? 8
                            : data?.type === "tennis"
                              ? 7
                              : data?.type === "golf"
                                ? 16
                                : "";

    const tournamentIds =
      layoutData?.sportFeaturedFixtures?.length > 0
        ? layoutData?.sportFeaturedFixtures
            ?.filter((item) => item?.sportId == homeSportId)?.[0]
            ?.tournament?.toString()
        : "";
    let passApi = data
      ? `/sportFeatured?SportId=${SportId ? SportId : ""}`
      : `/sportFeatured?SportId=${homeSportId ? homeSportId : ""}&tournamentIds=${tournamentIds ? tournamentIds : ""}`;
    try {
      const { status, data } = await axiosInstance.get(passApi, {
        signal: newAbortController.signal,
      });
      if (status === 200) {
        setFeatureSportLoader(false);
        setFeatureSportData(data?.result);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        setFeatureSportLoader(false);
      }
    }
  };

  const raceIcon = (name) => {
    if (name === "American Football") {
      return <AmericanFootball />;
    } else if (name === "Australian Rules") {
      return <AustralianRules />;
    } else if (name === "Baseball") {
      return <Baseball />;
    } else if (name === "Basketball") {
      return <Basketball />;
    } else if (name === "Boxing") {
      return <Boxing />;
    } else if (name === "Cricket") {
      return <Cricket />;
    } else if (name === "Golf") {
      return <Golf />;
    } else if (name === "Ice Hockey") {
      return <IceHockey />;
    } else if (name === "Mixed Martial Arts") {
      return <MixedMartialArts />;
    } else if (name === "Rugby League") {
      return <RugbyLeague />;
    } else if (name === "Rugby Union") {
      return <RugbyUnion />;
    } else if (name === "Soccer") {
      return <Soccer />;
    } else if (name === "Tennis") {
      return <Tennis />;
    }
  };
  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    Isscore,
    OddsType,
    teamType,
  ) => {
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    const oddsInfo = BookKeeperData?.[0];
    let isAdded = BetslipData?.some((el) => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      )
        return true;
      else return false;
    });

    return (
      <>
        {odds || odds == 0 ? (
          <span
            className={`odds-btn cursor-pointer current-best-odds-value  ${
              isAdded && IsBetslip === "betslip" ? "betslip-added" : ""
            } `}
            onClick={(e) => {
              IsBetslip === "nobetslip"
                ? handleBookkeeperCounter(BookKeeperId, type, e)
                : isAdded
                  ? handleDeleteBetslip(BookKeeperId, item, fulldata, e)
                  : handleAddToBetSlip(
                      BookKeeperId,
                      type,
                      item,
                      fulldata,
                      Isscore,
                      e,
                    );
            }}
          >
            <span className="odds-link">
              {IsBetslip === "betslip" && !Isscore ? (
                <Tooltip title="Bet" className="bet-tooltip" placement="top">
                  <span className="bet-now">
                    {" "}
                    {odds == 0 ? "SP" : Number(odds).toFixed(2)}{" "}
                  </span>
                </Tooltip>
              ) : (
                <>{odds == 0 ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </span>
          </span>
        ) : null}
      </>
    );
  };
  const fetchCurrentBestOdds = (data, team) => {
    let allTeamOdds =
      data?.Sport?.sportName === "Cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : data?.Sport?.sportName === "Basketball"
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : data?.Sport?.sportName === "American Football"
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : data?.Sport?.sportName === "Australian Rules"
              ? data?.ARBetOffers?.[0]?.AROdds
              : data?.Sport?.sportName === "Golf"
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : data?.Sport?.sportName === "Tennis"
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : data?.Sport?.sportName === "Baseball"
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : data?.Sport?.sportName === "Ice Hockey"
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : data?.Sport?.sportName === "Boxing"
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : data?.Sport?.sportName === "Mixed Martial Arts"
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : data?.Sport?.sportName === "Soccer"
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return data?.Sport?.sportName === "Cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : data?.Sport?.sportName === "Basketball"
          ? item?.NBATeamId == data?.homeTeamId
          : data?.Sport?.sportName === "American Football"
            ? item?.AFLTeamId == data?.homeTeamId
            : data?.Sport?.sportName === "Australian Rules"
              ? item?.ARTeamId == data?.homeTeamId
              : data?.Sport?.sportName === "Golf"
                ? item?.GolfTeamId == data?.homeTeamId
                : data?.Sport?.sportName === "Tennis"
                  ? item?.TennisTeamId == data?.homeTeamId
                  : data?.Sport?.sportName === "Baseball"
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : data?.Sport?.sportName === "Ice Hockey"
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : data?.Sport?.sportName === "Boxing"
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : data?.Sport?.sportName === "Mixed Martial Arts"
                          ? item?.MMATeamId == data?.homeTeamId
                          : data?.Sport?.sportName === "Soccer"
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return data?.Sport?.sportName === "Cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : data?.Sport?.sportName === "Basketball"
          ? item?.NBATeamId == data?.awayTeamId
          : data?.Sport?.sportName === "American Football"
            ? item?.AFLTeamId == data?.awayTeamId
            : data?.Sport?.sportName === "Australian Rules"
              ? item?.ARTeamId == data?.awayTeamId
              : data?.Sport?.sportName === "Golf"
                ? item?.GolfTeamId == data?.awayTeamId
                : data?.Sport?.sportName === "Tennis"
                  ? item?.TennisTeamId == data?.awayTeamId
                  : data?.Sport?.sportName === "Baseball"
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : data?.Sport?.sportName === "Ice Hockey"
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : data?.Sport?.sportName === "Boxing"
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : data?.Sport?.sportName === "Mixed Martial Arts"
                          ? item?.MMATeamId == data?.awayTeamId
                          : data?.Sport?.sportName === "Soccer"
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });
    let Isscore = data?.status === "inprogress" || data?.ScoreBoard?.Epr === 1;
    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;

    let teamData = teamInfo;

    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);

    if (maxno !== -1) {
      if (maxno && maxno !== 0) {
        let bookkeeperid = teamData
          ?.map((obj) => {
            if (obj?.odd === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter((x) => x !== undefined);
        return (
          <Box className="current-best-odds-wrap">
            {fetchClickableOdds(
              maxno,
              bookkeeperid?.[0],
              "header",
              teamData,
              data,
              "betslip",
              Isscore,
              "currentbest",
            )}
            {/* <span className="current-best-odds-value">
              {maxno ? maxno : "NOA"}
            </span> */}
            <Box className="current-best-odds-icon">
              {oddsicon(bookkeeperid?.[0], "header", teamData, data)}
            </Box>
          </Box>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const oddsicon = (BookKeeperId, type, item, fullData) => {
    const Identifiers =
      fullData?.SportId === 4
        ? fullData?.CricketIdentifiers
        : fullData?.SportId === 12 || fullData?.SportId === 13
          ? fullData?.RLIdentifiers
          : fullData?.SportId === 10
            ? fullData?.NBAIdentifiers
            : fullData?.SportId === 15
              ? fullData?.AFLIdentifiers
              : fullData?.SportId === 9
                ? fullData?.ARIdentifiers
                : fullData?.SportId === 16
                  ? fullData?.GolfIdentifiers
                  : fullData?.SportId === 7
                    ? fullData?.TennisIdentifiers
                    : fullData?.SportId === 11
                      ? fullData?.BaseballIdentifiers
                      : fullData?.SportId === 17
                        ? fullData?.IceHockeyIdentifiers
                        : fullData?.SportId === 6
                          ? fullData?.BoxingIdentifiers
                          : fullData?.SportId === 5
                            ? fullData?.MMAIdentifiers
                            : fullData?.SportId === 8
                              ? fullData?.SoccerIdentifiers
                              : fullData?.RLIdentifiers;
    const newData = Identifiers?.map((obj) => {
      const BookkeeperObj = obj?.ApiProvider?.BookKeeperProviders?.filter(
        (item) => {
          return item?.BookKeeperId === BookKeeperId;
        },
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null,
      };
    });
    const filteredData = newData
      ?.filter?.((obj) => obj?.BookKeeperId === BookKeeperId)
      ?.filter((obj) => obj?.ApiProviderId !== 17);

    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    let imageUrl = iconData?.currentBest_logo;
    if (BookKeeperId === 8) {
      const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.filter(
        (ele) => ele?.providerMarketId && ele?.providerParticipantId,
      );
      const urlLink =
        Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
          ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
          : `${iconData?.affiliate_link}`;
      return (
        <Box
          // href={
          //
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="current-best-odds-icon"
          // onClick={e => handleAnchorTagClick(e, urlLink)}
        >
          <img
            className="currentbest-bookmaker-thumb"
            src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
            onClick={(e) => {
              handleBookkeeperCounter(BookKeeperId, type, e);
              handleAnchorTagClick(e, urlLink);
            }}
            alt="Odds Icon"
          />
        </Box>
      );
    } else {
      const urlLink =
        filteredData?.length > 0 && filteredData?.[0]?.url
          ? filteredData?.[0]?.url + `?Referrer=SmartB`
          : iconData?.affiliate_link;
      return (
        <Box
          // href={
          //   filteredData?.length > 0 && filteredData?.[0]?.url
          //     ? filteredData?.[0]?.url + `?Referrer=SmartB`
          //     : iconData?.affiliate_link
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="current-best-odds-icon"
          // onClick={e => handleAnchorTagClick(e, urlLink)}
        >
          <img
            className="currentbest-bookmaker-thumb"
            src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
            onClick={(e) => {
              handleBookkeeperCounter(BookKeeperId, type, e);
              handleAnchorTagClick(e, urlLink);
            }}
            alt="Odds Icon"
          />
        </Box>
      );
    }
  };
  const handleBookkeeperCounter = async (BookKeeperId, type, e, SportId) => {
    e.stopPropagation();

    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(SportId),
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload,
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const handleAnchorTagClick = (e, url) => {
    window.open(url, "_blank");
    e.stopPropagation();
  };
  const handleNavigate = useCallback(
    (data) => {
      if (!dragging) {
        let sportName =
          data?.Sport?.sportName === "Cricket"
            ? "cricket"
            : data?.Sport?.sportName === "Basketball"
              ? "basketball"
              : data?.Sport?.sportName === "American Football"
                ? "americanfootball"
                : data?.Sport?.sportName === "Australian Rules"
                  ? "australianrules"
                  : data?.Sport?.sportName === "Golf"
                    ? "golf"
                    : data?.Sport?.sportName === "Tennis"
                      ? "tennis"
                      : data?.Sport?.sportName === "Baseball"
                        ? "baseball"
                        : data?.Sport?.sportName === "Ice Hockey"
                          ? "icehockey"
                          : data?.Sport?.sportName === "Boxing"
                            ? "boxing"
                            : data?.Sport?.sportName === "Mixed Martial Arts"
                              ? "mma"
                              : data?.Sport?.sportName === "Soccer"
                                ? "soccer"
                                : data?.Sport?.sportName === "Rugby League"
                                  ? "rugbyleague"
                                  : data?.Sport?.sportName === "Rugby Union"
                                    ? "rugbyunion"
                                    : "rugbyunionsevens";
        if (Config?.release == "IN") {
          if (data?.SportId === 4) {
            navigate(
              `/all-sports/${sportName}/${data?.SportId}/odds/0/false?menu=fixtures_results&touranamentId=0&touranamentName=All+Leagues`,
            );
          } else {
            navigate(`/teamsports/${sportName}/odds/0/false`);
          }
        } else {
          if (data?.SportId === 4) {
            navigate(
              `/all-sports/${sportName}/${data?.SportId}/odds/${data?.id}/true?menu=fixtures_results&touranamentId=0&touranamentName=All+Leagues`,
            );
          } else {
            navigate(`/teamsports/${sportName}/odds/${data?.id}/true`);
          }
        }
      }
    },
    [dragging],
  );
  const handleAddToBetSlip = async (
    BookKeeperId,
    type,
    item,
    fulldata,
    Isscore,
    e,
  ) => {
    e.stopPropagation();
    handleBookkeeperCounter(BookKeeperId, type, e, fulldata?.SportId);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    const oddsInfo = BookKeeperData?.[0];
    let passTeamId =
      fulldata?.SportId == 4
        ? oddsInfo?.CricketTeamId
          ? oddsInfo?.CricketTeamId
          : oddsInfo?.CricketOddLabelId
            ? oddsInfo?.CricketOddLabelId
            : oddsInfo?.CricketPlayerId
        : fulldata?.SportId == 10
          ? oddsInfo?.NBATeamId
            ? oddsInfo?.NBATeamId
            : oddsInfo?.NBAOddLabelId
              ? oddsInfo?.NBAOddLabelId
              : oddsInfo?.NBAPlayerId
          : fulldata?.SportId == 15
            ? oddsInfo?.AFLTeamId
              ? oddsInfo?.AFLTeamId
              : oddsInfo?.AFLOddLabelId
                ? oddsInfo?.AFLOddLabelId
                : oddsInfo?.AFLPlayerId
            : fulldata?.SportId == 9
              ? oddsInfo?.ARTeamId
                ? oddsInfo?.ARTeamId
                : oddsInfo?.AROddLabelId
                  ? oddsInfo?.AROddLabelId
                  : oddsInfo?.ARPlayerId
              : fulldata?.SportId == 16
                ? oddsInfo?.GolfTeamId
                  ? oddsInfo?.GolfTeamId
                  : oddsInfo?.GolfOddLabelId
                    ? oddsInfo?.GolfOddLabelId
                    : oddsInfo?.GolfPlayerId
                : fulldata?.SportId == 7
                  ? oddsInfo?.TennisTeamId
                    ? oddsInfo?.TennisTeamId
                    : oddsInfo?.TennisOddLabelId
                      ? oddsInfo?.TennisOddLabelId
                      : oddsInfo?.TennisPlayerId
                  : fulldata?.SportId == 11
                    ? oddsInfo?.BaseballTeamId
                      ? oddsInfo?.BaseballTeamId
                      : oddsInfo?.BaseballOddLabelId
                        ? oddsInfo?.BaseballOddLabelId
                        : oddsInfo?.BaseballPlayerId
                    : fulldata?.SportId == 17
                      ? oddsInfo?.IceHockeyTeamId
                        ? oddsInfo?.IceHockeyTeamId
                        : oddsInfo?.IceHockeyOddLabelId
                          ? oddsInfo?.IceHockeyOddLabelId
                          : oddsInfo?.IceHockeyPlayerId
                      : fulldata?.SportId == 6
                        ? oddsInfo?.BoxingTeamId
                          ? oddsInfo?.BoxingTeamId
                          : oddsInfo?.BoxingOddLabelId
                            ? oddsInfo?.BoxingOddLabelId
                            : oddsInfo?.BoxingPlayerId
                        : fulldata?.SportId == 5
                          ? oddsInfo?.MMATeamId
                            ? oddsInfo?.MMATeamId
                            : oddsInfo?.MMAOddLabelId
                              ? oddsInfo?.MMAOddLabelId
                              : oddsInfo?.MMAPlayerId
                          : fulldata?.SportId == 8
                            ? oddsInfo?.SoccerTeamId
                              ? oddsInfo?.SoccerTeamId
                              : oddsInfo?.SoccerOddLabelId
                                ? oddsInfo?.SoccerOddLabelId
                                : oddsInfo?.SoccerPlayerId
                            : oddsInfo?.RLTeamId
                              ? oddsInfo?.RLTeamId
                              : oddsInfo?.RLOddLabelId
                                ? oddsInfo?.RLOddLabelId
                                : oddsInfo?.RLPlayerId;
    let payload = {
      sportId: fulldata?.SportId,
      eventId: fulldata?.id,
      teamId: passTeamId,
      bookKeeperId: BookKeeperId,
      betOfferId: oddsInfo?.id,
    };
    if (isLogin) {
      if (!Isscore) {
        try {
          const { status, data } = await axiosInstance.post(
            `betSlipCard/BetSlipCard`,
            payload,
          );
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });

            const addedData = [...BetslipData, data?.result];
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {
          }
        } catch (err) {
          console.log("error", err);
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
      if (!Isscore) {
        setApiInfo({
          api: `betSlipCard/BetSlipCard`,
          payload: payload,
          method: "post",
        });
      }
    }
  };
  const handleDeleteBetslip = async (BookKeeperId, item, fulldata, e) => {
    e.stopPropagation();
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    const oddsInfo = BookKeeperData?.[0];
    let delslip = BetslipData?.filter((el) => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`,
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        let chageData = BetslipData?.filter((obj) => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const getTeamScores = (data, teamId, teamType) => {
    let teamScore =
      data?.SportId === 4
        ? data?.ScoreBoard
        : data?.SportId === 10
          ? data?.NBAScores
          : data?.SportId === 15
            ? data?.AFLScores
            : data?.SportId === 9
              ? data?.ARScores
              : data?.SportId === 16
                ? data?.GolfScores
                : data?.SportId === 7
                  ? data?.TennisScores
                  : data?.SportId === 11
                    ? data?.BaseballScores
                    : data?.SportId === 17
                      ? data?.IceHockeyScores
                      : data?.SportId === 6
                        ? data?.BoxingScores
                        : data?.SportId === 5
                          ? data?.MMAScores
                          : data?.SportId === 8
                            ? data?.ScoreBoard
                            : data?.RLScores;
    const team =
      data?.SportId !== 4 &&
      data?.SportId !== 8 &&
      teamScore?.filter((item) => item?.teamId === teamId);
    const teamDataScore = team?.[0]?.score;
    const scores = teamDataScore?.current;
    return (
      <>
        {data?.SportId === 4 ? (
          <>
            {teamType === "hometeam" ? (
              teamScore?.Epr == 1 &&
              teamScore?.Tr1C1 == null &&
              teamScore?.Ebat == 2 ? (
                <Typography className="team-score">Yet to bat</Typography>
              ) : teamScore?.Exd > "1" ? (
                <Typography className="team-score">
                  {teamScore?.Tr1C1 ?? ""}
                  {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
                    ? ""
                    : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
                      `/${teamScore?.Tr1CW1}${
                        teamScore?.Tr1CD1 && teamScore?.Tr1CD1 === 1 ? "d" : ""
                      }`}{" "}
                  {(teamScore?.Tr1C2 || teamScore?.Tr1C2 === 0) &&
                    `& ${teamScore?.Tr1C2}`}
                  {teamScore?.Tr1CW2 && teamScore?.Tr1CW2 === 10
                    ? ""
                    : (teamScore?.Tr1CW2 || teamScore?.Tr1CW2 === 0) &&
                      `/${teamScore?.Tr1CW2}${
                        teamScore?.Tr1CD2 && teamScore?.Tr1CD2 === 1 ? "d" : ""
                      }`}{" "}
                </Typography>
              ) : (
                <Typography className="team-score">
                  {teamScore?.Tr1C1 ?? ""}
                  {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
                    ? ""
                    : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
                      `/ ${teamScore?.Tr1CW1}`}{" "}
                  <span>
                    {" "}
                    {teamScore?.Tr1CO1 ? `(${teamScore?.Tr1CO1})` : ""}
                  </span>{" "}
                </Typography>
              )
            ) : teamScore?.Epr == 1 &&
              teamScore?.Tr2C1 == null &&
              teamScore?.Ebat == 1 ? (
              <Typography className="team-score">Yet to bat</Typography>
            ) : teamScore?.Exd > "1" ? (
              <Typography className="team-score">
                {teamScore?.Tr2C1 ?? ""}
                {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
                  ? ""
                  : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
                    `/${teamScore?.Tr2CW1}${
                      teamScore?.Tr2CD1 && teamScore?.Tr2CD1 === 1 ? "d" : ""
                    }`}{" "}
                {(teamScore?.Tr2C2 || teamScore?.Tr2C2 === 0) &&
                  `& ${teamScore?.Tr2C2}`}
                {teamScore?.Tr2CW2 && teamScore?.Tr2CW2 === 10
                  ? ""
                  : (teamScore?.Tr2CW2 || teamScore?.Tr2CW2 === 0) &&
                    `/${teamScore?.Tr2CW2}${
                      teamScore?.Tr2CD2 && teamScore?.Tr2CD2 === 1 ? "d" : ""
                    }`}{" "}
              </Typography>
            ) : (
              <Typography className="team-score">
                {teamScore?.Tr2C1 ?? ""}
                {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
                  ? ""
                  : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
                    `/ ${teamScore?.Tr2CW1}`}{" "}
                <span>{teamScore?.Tr2CO1 ? `(${teamScore?.Tr2CO1})` : ""}</span>
              </Typography>
            )}
          </>
        ) : data?.SportId === 8 ? (
          <>
            {teamType === "hometeam" ? (
              <span>{teamScore?.Tr1 ?? "-"}</span>
            ) : (
              <sapn>{teamScore?.Tr2 ?? "-"}</sapn>
            )}{" "}
          </>
        ) : (
          <span className="team-score">{scores}</span>
        )}
      </>
    );
  };
  return (
    <>
      {/* individual sport feature */}
      {data ? (
        <>
          {featureSportLoader ? (
            <div className="allsport-loader-center news-loader">
              <Loader />
            </div>
          ) : featureSportData?.length > 0 ? (
            <Box
              className={
                featureSportData?.length === 1
                  ? "featured-fixture-slider-wrap single-slide"
                  : "featured-fixture-slider-wrap"
              }
            >
              <Box>
                <Typography variant="h3" className="home-slider-header">
                  {localesData?.HOME?.FEATURED_FIXTURES}
                </Typography>
                {/* <CustomTab
              mapData={allSportData}
              tabvalue={tabvalue}
              handleTabChange={handleTabChange}
            /> */}
                <Slider
                  {...settingsSport}
                  afterChange={handleAfterChange}
                  className="racing-slick-slider"
                >
                  {featureSportData?.map((item) => {
                    return (
                      <>
                        <Box
                          className={
                            item?.status === "inprogress" ||
                            item?.ScoreBoard?.Epr === 1 ||
                            item?.ScoreBoard?.Epr === 2
                              ? "racing-slider-details"
                              : " racing-slider-details sport-noresult-slider-details"
                          }
                          // onClick={() => {
                          //   handleNavigate(item);
                          // }}
                        >
                          <Box className="teamdetail-odds-wrap">
                            <Box className="icon-odds-wrap">
                              <Box className="icon-wrap">
                                {fetchTeamlogo(item, "hometeam")}
                              </Box>
                              {fetchCurrentBestOdds(item, "hometeam")}
                            </Box>
                            <Box className="team-details-container">
                              <Box className="tournament-indicator">
                                <span className="tournament-name">
                                  {item?.SportId == 4
                                    ? item?.CricketTournament?.name
                                    : item?.SportId == 12 ||
                                        item?.SportId == 13 ||
                                        item?.SportId == 14
                                      ? item?.RLTournament?.name
                                      : item?.SportId == 15
                                        ? item?.AFLTournament?.name
                                        : item?.SportId == 9
                                          ? item?.ARTournament?.name
                                          : item?.SportId == 16
                                            ? item?.GolfTournament?.name
                                            : item?.SportId == 7
                                              ? item?.TennisTournament?.name
                                              : item?.SportId == 11
                                                ? item?.BaseballTournament?.name
                                                : item?.SportId == 17
                                                  ? item?.IceHockeyTournament
                                                      ?.name
                                                  : item?.SportId == 6
                                                    ? item?.BoxingTournament
                                                        ?.name
                                                    : item?.SportId == 5
                                                      ? item?.MMATournament
                                                          ?.name
                                                      : item?.SportId == 8
                                                        ? item?.SoccerTournament
                                                            ?.name
                                                        : item?.SportId == 10
                                                          ? item?.NBATournament
                                                              ?.NBACategory ===
                                                            null
                                                            ? item
                                                                ?.NBATournament
                                                                ?.name
                                                            : item
                                                                ?.NBATournament
                                                                ?.name +
                                                              " " +
                                                              item
                                                                ?.NBATournament
                                                                ?.NBACategory
                                                                ?.name
                                                          : ""}
                                </span>
                              </Box>
                              <Box>
                                <Tooltip
                                  title={item?.eventName}
                                  placement="bottom"
                                  arrow
                                >
                                  <span className="event-name">
                                    {item?.eventName}
                                  </span>
                                </Tooltip>
                              </Box>
                              <Box className="date-time">
                                {" "}
                                {moment
                                  .utc(item?.startTime)
                                  .local()
                                  .format("DD/MM/YYYY | hh:mm A")}
                              </Box>
                              <Box
                                className="compare-odds cursor-pointer"
                                onClick={() => {
                                  handleNavigate(item);
                                }}
                              >
                                Compare odds
                              </Box>
                            </Box>
                            <Box className="icon-odds-wrap">
                              <Box className="icon-wrap">
                                {fetchTeamlogo(item, "awayteam")}
                              </Box>
                              {fetchCurrentBestOdds(item, "awayteam")}
                            </Box>
                            {item?.status === "inprogress" ||
                            item?.ScoreBoard?.Epr === 1 ? (
                              <span className="live-indicator">LIVE</span>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="result-wrap">
                            {item?.status === "inprogress" ||
                            item?.ScoreBoard?.Epr === 1 ||
                            item?.ScoreBoard?.Epr === 2 ? (
                              <Box className="featured-sport-score">
                                <Box className="score-details">
                                  <span className="score">
                                    {getTeamScores(
                                      item,
                                      item?.homeTeamId,
                                      "hometeam",
                                    )}
                                  </span>
                                  <span className="score-team">
                                    {" "}
                                    {item?.homeTeam?.name}
                                  </span>
                                </Box>

                                <Box className="score-details">
                                  <span className="score">
                                    {getTeamScores(
                                      item,
                                      item?.awayTeamId,
                                      "awayteam",
                                    )}
                                  </span>
                                  <span className="score-team ">
                                    {item?.awayTeam?.name}
                                  </span>
                                </Box>
                              </Box>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                </Slider>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </>
      ) : (
        allSportData?.length > 0 && (
          <Box className="featured-fixture-slider-wrap">
            {/* Home Page feature */}
            <Box>
              <Typography variant="h3" className="home-slider-header">
                {localesData?.HOME?.FEATURED_FIXTURES}
              </Typography>
              <CustomTab
                mapData={allSportData}
                tabvalue={tabvalue}
                handleTabChange={handleTabChange}
                mode={"dark"}
              />
              <Box className="slider-data-container">
                {featureSportLoader ? (
                  <div className="allsport-loader-center featured-loader">
                    <Loader />
                  </div>
                ) : featureSportData?.length > 0 ? (
                  <Slider
                    {...settingsSport}
                    afterChange={handleAfterChange}
                    className="racing-slick-slider"
                  >
                    {featureSportData?.map((item) => {
                      return (
                        <>
                          <Box
                            className={
                              item?.status === "inprogress" ||
                              item?.ScoreBoard?.Epr === 1 ||
                              item?.ScoreBoard?.Epr === 2
                                ? "racing-slider-details"
                                : " racing-slider-details sport-noresult-slider-details"
                            }
                            // onClick={() => {
                            //   handleNavigate(item);
                            // }}
                          >
                            <Box className="teamdetail-odds-wrap">
                              <Box className="icon-odds-wrap">
                                <Box className="icon-wrap">
                                  {fetchTeamlogo(item, "hometeam")}
                                </Box>
                                {fetchCurrentBestOdds(item, "hometeam")}
                              </Box>
                              <Box className="team-details-container">
                                <Box className="tournament-indicator">
                                  <span className="tournament-name">
                                    {item?.SportId == 4
                                      ? item?.CricketTournament?.name
                                      : item?.SportId == 12 ||
                                          item?.SportId == 13 ||
                                          item?.SportId == 14
                                        ? item?.RLTournament?.name
                                        : item?.SportId == 15
                                          ? item?.AFLTournament?.name
                                          : item?.SportId == 9
                                            ? item?.ARTournament?.name
                                            : item?.SportId == 16
                                              ? item?.GolfTournament?.name
                                              : item?.SportId == 7
                                                ? item?.TennisTournament?.name
                                                : item?.SportId == 11
                                                  ? item?.BaseballTournament
                                                      ?.name
                                                  : item?.SportId == 17
                                                    ? item?.IceHockeyTournament
                                                        ?.name
                                                    : item?.SportId == 6
                                                      ? item?.BoxingTournament
                                                          ?.name
                                                      : item?.SportId == 5
                                                        ? item?.MMATournament
                                                            ?.name
                                                        : item?.SportId == 8
                                                          ? item
                                                              ?.SoccerTournament
                                                              ?.name
                                                          : item?.SportId == 10
                                                            ? item
                                                                ?.NBATournament
                                                                ?.NBACategory ===
                                                              null
                                                              ? item
                                                                  ?.NBATournament
                                                                  ?.name
                                                              : item
                                                                  ?.NBATournament
                                                                  ?.name +
                                                                " " +
                                                                item
                                                                  ?.NBATournament
                                                                  ?.NBACategory
                                                                  ?.name
                                                            : ""}
                                  </span>
                                </Box>
                                <Box>
                                  <Tooltip
                                    title={item?.eventName}
                                    placement="bottom"
                                    arrow
                                  >
                                    <span className="event-name">
                                      {item?.eventName}
                                    </span>
                                  </Tooltip>
                                </Box>
                                <Box className="date-time">
                                  {" "}
                                  {moment
                                    .utc(item?.startTime)
                                    .local()
                                    .format("DD/MM/YYYY | hh:mm A")}
                                </Box>
                                <Box
                                  className="compare-odds cursor-pointer"
                                  onClick={() => {
                                    handleNavigate(item);
                                  }}
                                >
                                  Compare odds
                                </Box>
                              </Box>
                              <Box className="icon-odds-wrap">
                                <Box className="icon-wrap">
                                  {fetchTeamlogo(item, "awayteam")}
                                </Box>
                                {fetchCurrentBestOdds(item, "awayteam")}
                              </Box>
                              {item?.status === "inprogress" ||
                              item?.ScoreBoard?.Epr === 1 ? (
                                <span className="live-indicator">LIVE</span>
                              ) : (
                                <></>
                              )}
                            </Box>
                            <Box className="result-wrap">
                              {item?.status === "inprogress" ||
                              item?.ScoreBoard?.Epr === 1 ||
                              item?.ScoreBoard?.Epr === 2 ? (
                                <Box className="featured-sport-score">
                                  <Box className="score-details">
                                    <span className="score">
                                      {getTeamScores(
                                        item,
                                        item?.homeTeamId,
                                        "hometeam",
                                      )}
                                    </span>
                                    <span className="score-team">
                                      {" "}
                                      {item?.homeTeam?.name}
                                    </span>
                                  </Box>

                                  <Box className="score-details">
                                    <span className="score">
                                      {getTeamScores(
                                        item,
                                        item?.awayTeamId,
                                        "awayteam",
                                      )}
                                    </span>
                                    <span className="score-team ">
                                      {item?.awayTeam?.name}
                                    </span>
                                  </Box>
                                </Box>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                  </Slider>
                ) : (
                  <Box className="nodata-feature f-16">No data Available</Box>
                )}
              </Box>
            </Box>
          </Box>
        )
      )}
    </>
  );
};

export default FeaturedSportV2;
