import React, { useEffect, useState } from "react";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { fetchTournamentDataStore } from "src/helpers/store/Actions/tournamentDataStore";
import { useSelector } from "react-redux";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const TournamnetListSelect = ({
  selectOption,
  setSelectOption,
  selectedValue,
  setSelectedValue,
  handleSelectedValueChange,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const TournamentData = useSelector(
    (state) => state?.reduxData?.tournamentData,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (TournamentData?.length > 0) {
      const targetTournament = TournamentData?.find(
        (item) => item?.label === "Big Bash League",
      );
      setSelectOption(TournamentData);
      setSelectedValue(targetTournament?.value);
    } else {
      fetchTournamnetList();
    }

    return () => {
      dispatch(fetchTournamentDataStore([]));
    };
  }, []);

  const fetchTournamnetList = async () => {
    setLoading(true);
    const passApi = `public/tournament?SportId=${params?.sportsId}`;
    const { status, data } = await axiosInstance.get(passApi);
    try {
      if (status === 200) {
        const leagueData = data?.result?.rows?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        const targetTournament = leagueData?.find(
          (item) => item?.label === "Big Bash League",
        );
        setSelectOption(leagueData);
        setSelectedValue(targetTournament?.value);
        dispatch(fetchTournamentDataStore(leagueData));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  return (
    <>
      <Select
        className="React league-select"
        value={selectOption?.find((item) => {
          return item?.value === selectedValue;
        })}
        onChange={(e) => {
          handleSelectedValueChange(e);
        }}
        options={selectOption}
        classNamePrefix="select"
        placeholder="league"
        isSearchable={true}
        isLoading={loading}
        components={{ DropdownIndicator }}
      />
    </>
  );
};

export default TournamnetListSelect;
