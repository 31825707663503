import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Box, Button, Chip, Typography } from "@mui/material";
// import Carousel, { consts } from "react-elastic-carousel";
import Countdown from "react-countdown";
import horse from "../../../assets/images/filter1.svg";
import greys from "../../../assets/images/Sport/Greyhound.svg";
import harnes from "../../../assets/images/Sport/Harness.svg";
import Football from "../../../assets/images/Sport/football.svg";
import Baseball from "../../../assets/images/Sport/baseball.svg";
import Basketball from "../../../assets/images/Sport/basketball.svg";
import Boxing from "../../../assets/images/Sport/boxing.svg";
import Cricket from "../../../assets/images/Sport/cricket.svg";
import Golf from "../../../assets/images/Sport/golf.svg";
import IceHockey from "../../../assets/images/Sport/iceHockey.svg";
import MMA from "../../../assets/images/Sport/mma.svg";
import Rugby from "../../../assets/images/Sport/rugby.svg";
import Soccer from "../../../assets/images/Sport/soccer.svg";
import Tennis from "../../../assets/images/Sport/Tennis.svg";
import AR from "../../../assets/images/Sport/AR.svg";
import RU from "../../../assets/images/Sport/RU.svg";
import FRE from "../../../assets/images/Sport/GWSLogo.png";
import GWS from "../../../assets/images/Sport/FRELogo.png";
import axiosInstance from "../../../helpers/Axios/axiosInstance";
import "./headertopbar.scss";
import { Routes } from "../../../helpers/constants/routeConstants";
import { ReactComponent as NextSlide } from "../../../assets/images/icons/slide-next.svg";
import { ReactComponent as PrvSlide } from "../../../assets/images/icons/slide-prv.svg";
import { ReactComponent as WinArrow } from "../../../assets/images/icons/redWinArrow.svg";
import { ReactComponent as Batting } from "../../../assets/images/icons/batting.svg";
import { ReactComponent as Bowling } from "../../../assets/images/icons/bowling.svg";
import { fetchUpcomingRaces } from "../../../helpers/store/Actions/sport";
import { fetchAdvData } from "../../../helpers/store/Actions/AdvertisingScreenData";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Config } from "src/helpers/context";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// Renderer callback with condition for chip of timer

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return (
      <Chip
        className={
          (hours === 0 && minutes < 5 ? "bg-orange" : "") + " sqare-chip "
        }
        size="small"
        label={<span> Closed </span>}
      />
    );
  } else {
    // Render a countdown
    return (
      <Chip
        className={
          (hours === 0 && minutes < 5 ? "bg-orange" : "") + " sqare-chip "
        }
        size="small"
        label={
          <span>
            {days > 0
              ? `${days}d ${hours}h`
              : hours > 0
                ? `${hours}h ${minutes}m`
                : minutes > 0
                  ? `${minutes}m`
                  : seconds > 0
                    ? `${seconds}s`
                    : ""}
          </span>
        }
      />
    );
  }
};

const breakPoints = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1 },
  { width: 250, itemsToShow: 1, itemsToScroll: 1 },
  { width: 300, itemsToShow: 1, itemsToScroll: 1 },
  { width: 350, itemsToShow: 2, itemsToScroll: 2 },
  { width: 400, itemsToShow: 2, itemsToScroll: 2 },
  { width: 700, itemsToShow: 3, itemsToScroll: 3 },
  { width: 880, itemsToShow: 6, itemsToScroll: 6 },
];

const UpcommingCarousel = ({
  filter,
  country,
  sport,
  isLive,
  resultdata,
  setResultdata,
  jumpStartDate,
  setJumpStartDate,
  prevOffset,
  setPrevOffset,
  nextOffset,
  setNextOffset,
  disableNext,
  disablePrev,
  setDisableNext,
  setDisablePrev,
  isSameAsFilter,
  setIsSameAsFilter,
}) => {
  const navigate = useNavigate();
  const carouselRef = useRef(null);
  const hasCompletedRef = useRef(false);

  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const [isRunnerLoad, setisRunnerLoad] = useState(false);
  const [sliderData, setSliderData] = useState([]);
  const [isTopBarLoading, setIsTopBarLoading] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [prevData, setPrevData] = useState();
  const [nextData, setNextData] = useState();
  const [nextSearchDate, setNextSearchDate] = useState();
  const [pastSearchDate, setPastSearchDate] = useState();
  const [activePageState, setActivePageState] = useState();
  const [maxIndexState, setMaxIndexState] = useState();
  const [activeSlide, setActiveSlide] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // const [prevOffset, setPrevOffset] = useState(0);
  // const [nextOffset, setNextOffset] = useState(0)

  const reduxBannerData = useSelector(
    (state) => state?.reduxData?.upcomingRaces
  );
  const reduxSubscriptionData = useSelector(
    (state) => state?.reduxData?.SubscripitionData
  );
  const currentJumpDate = moment().format("YYYY-MM-DD");
  const token = localStorage.getItem("auth_token");

  // useEffect(() => {
  //   // update sliderBar when data has ready : for index = 0 but prevData not readyv

  //   const currentSlideIndex = activePageState;
  //   const maxSlideIndex = maxIndexState;

  //   // past events
  //   prevData && prevData.length && currentSlideIndex === 0 && MergePrevData();
  //   // future events
  //   nextData &&
  //     nextData.length &&
  //     currentSlideIndex &&
  //     currentSlideIndex === maxSlideIndex &&
  //     MergeNextData();
  // }, [prevData, nextData]);

  useEffect(() => {
    if (
      (reduxSubscriptionData && token) ||
      (!reduxSubscriptionData && !token)
    ) {
      GetUpcomingRace();
    } else {
      setIsTopBarLoading(true);
    }
  }, [country, filter, sport, isLive]);

  useEffect(() => {
    if (isSameAsFilter) {
      GetUpcomingRace();
      setIsSameAsFilter(false);
    }
  }, [isSameAsFilter]);

  useEffect(() => {
    handleAdvertisingScreen();
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  const GetUpcomingRace = async () => {
    setIsTopBarLoading(true);
    let SelectedSportId = [...filter, ...sport];
    let SelectedState = country;

    try {
      // default limit=80
      const nextJumpSportUrlArray = isLive
        ? [
          `nextJumpSport?sportId=${SelectedSportId}&timezone=${timezone}&type=next&MeetingState=${SelectedState}&status=${isLive ? `inprogress` : ``
          }`,
        ]
        : [
          `nextJumpSport?sportId=${SelectedSportId}&timezone=${timezone}&type=next&MeetingState=${SelectedState}&status=`,
          `nextJumpSport?sportId=${SelectedSportId}&timezone=${timezone}&type=prev&limit=48&MeetingState=${SelectedState}&status=`,
        ];
      // DefaultData [80 items], FirstPrevData[40 items]
      let DefaultData, FirstPrevData;
      await Promise.all(
        nextJumpSportUrlArray?.map(async (item, i) => {
          if (i === 0) DefaultData = await axiosInstance.get(item);
          if (i === 1) FirstPrevData = await axiosInstance.get(item);
        })
      );
      const conditionTocheck = isLive
        ? DefaultData?.status === 200
        : DefaultData?.status === 200 && FirstPrevData?.status === 200;
      if (conditionTocheck) {
        // Merge All Three Races Array With Filter Undefine
        let MergeData = [];
        if (!isLive) {
          // setSliderIndex(FirstPrevData?.data?.result?.length);
          // setActiveSlide(FirstPrevData?.data?.result?.length);
          setSliderIndex(
            DefaultData?.data?.result?.length > 0
              ? FirstPrevData?.data?.result?.length
              : screenWidth >= 1120
                ? FirstPrevData?.data?.result?.length - settings?.slidesToShow
                : FirstPrevData?.data?.result?.length
          );
          setActiveSlide(
            DefaultData?.data?.result?.length > 0
              ? FirstPrevData?.data?.result?.length
              : screenWidth >= 1120
                ? FirstPrevData?.data?.result?.length - settings?.slidesToShow
                : FirstPrevData?.data?.result?.length
          );
          MergeData = [
            ...FirstPrevData?.data?.result,
            ...DefaultData?.data?.result,
          ];
        } else {
          MergeData = [...DefaultData?.data?.result];
          setSliderIndex(0);
          setActiveSlide(0);
        }

        let FinalData = MergeData?.sort(function (a, b) {
          return new Date(a?.eventDate) - new Date(b?.eventDate);
        });

        setDisableNext(
          FinalData?.length === 0 || DefaultData?.data?.result?.length < 96
        );
        setDisablePrev(
          FinalData?.length === 0 ||
          Boolean(FirstPrevData?.data?.result?.length < 48)
        );
        setSliderData(FinalData);
        dispatch(fetchUpcomingRaces(FinalData));
        setNextSearchDate(FinalData.slice(-1)[0].eventDate); // set date for search
        setPastSearchDate(FinalData[0].eventDate); // set date for search
        setIsTopBarLoading(false);
      } else {
        setIsTopBarLoading(false);
      }
    } catch (err) {
      setIsTopBarLoading(false);
    }
  };
  const fetchpastResultdata = async (offset) => {
    let SelectedSportId = [...filter, ...sport];
    let SelectedState = country;

    try {
      const { status, data } = await axiosInstance.get(
        `nextJumpSport?sportId=${SelectedSportId}&timezone=${timezone}&type=prev&page=${offset}&limit=48&MeetingState=${SelectedState}&searchDate=${pastSearchDate}&status=${isLive ? `inprogress` : ``
        }`
      );
      if (status === 200) {
        setPrevOffset(offset);
        setPrevData(data?.result);
        setDisablePrev(data?.result?.length < 48);
      }
    } catch (err) {
      // setIsTopBarLoading(false)
    }
  };

  const fetchNextResultdata = async (offset) => {
    let SelectedSportId = [...filter, ...sport];
    let SelectedState = country;
    try {
      const { status, data } = await axiosInstance.get(
        `nextJumpSport?sportId=${SelectedSportId}&timezone=${timezone}&type=next&page=${offset}&limit=48&MeetingState=${SelectedState}&searchDate=${nextSearchDate}&status=${isLive ? `inprogress` : ``
        }`
      );
      if (status === 200) {
        setNextOffset(offset);
        setNextData(data?.result);
        setDisableNext(data?.result?.length < 48);
      }
    } catch (err) {
      // setIsTopBarLoading(false)
    }
  };
  const MergePrevData = () => {
    setSliderIndex(prevData?.length - carouselRef.current?.props?.slidesToShow);
    setActiveSlide(prevData?.length - carouselRef.current?.props?.slidesToShow);
    const mergedData = [...prevData, ...sliderData];
    let FinalData = mergedData?.sort(function (a, b) {
      return new Date(a?.eventDate) - new Date(b?.eventDate);
    });

    setSliderData(FinalData);
    setNextSearchDate(FinalData.slice(-1)[0].eventDate); // set date for search
    setPastSearchDate(FinalData[0].eventDate); // set date for search
    dispatch(fetchUpcomingRaces(FinalData));
    setPrevData([]); // empty data after merge
    setActivePageState(undefined);
    setTimeout(() => {
      setIsTopBarLoading(false);
    }, 500);
  };
  const MergeNextData = () => {
    setSliderIndex(
      sliderData?.length - carouselRef.current?.props?.slidesToShow
    );
    setActiveSlide(
      sliderData?.length - carouselRef.current?.props?.slidesToShow
    );
    const mergedData = [...sliderData, ...nextData];
    let FinalData = mergedData?.sort(function (a, b) {
      return new Date(a?.eventDate) - new Date(b?.b?.eventDate);
    });

    setSliderData(FinalData);
    setNextSearchDate(FinalData.slice(-1)[0].eventDate); // set date for search
    setPastSearchDate(FinalData[0].eventDate); // set date for search
    dispatch(fetchUpcomingRaces(FinalData));
    setNextData([]); // empty data after merge
    setActivePageState(undefined);
    setTimeout(() => {
      setIsTopBarLoading(false);
    }, 500);
  };

  const handleRaceNavigation = (item) => {
    if (item?.sportId === 1 || item?.sportId === 2 || item?.sportId === 3) {
      let race =
        item?.sportId === 1
          ? "horse"
          : item?.sportId === 2
            ? "harness"
            : "greyhounds";
      navigate(
        Routes.RunnerDetails(
          race,
          item?.sportId,
          item?.id,
          item?.trackId,
          item?.eventId,
          item?.eventDate,
          item?.countryId === 13 || item?.countryId === 157 ? false : true
        )
      );
    } else {
      let sportName =
        item?.sportName?.trim() === "Cricket"
          ? "cricket"
          : item?.sportName?.trim() === "Basketball"
            ? "basketball"
            : item?.sportName?.trim() === "American Football"
              ? "americanfootball"
              : item?.sportName?.trim() === "Australian Rules"
                ? "australianrules"
                : item?.sportName?.trim() === "Golf"
                  ? "golf"
                  : item?.sportName?.trim() === "Tennis"
                    ? "tennis"
                    : item?.sportName?.trim() === "Baseball"
                      ? "baseball"
                      : item?.sportName?.trim() === "Ice Hockey"
                        ? "icehockey"
                        : item?.sportName?.trim() === "Boxing"
                          ? "boxing"
                          : item?.sportName?.trim() === "Mixed Martial Arts"
                            ? "mma"
                            : item?.sportName?.trim() === "Soccer"
                              ? "soccer"
                              : item?.sportName?.trim() === "Rugby League"
                                ? "rugbyleague"
                                : item?.sportName?.trim() === "Rugby Union"
                                  ? "rugbyunion"
                                  : "rugbyunionsevens";
      if (Config.release == "IN") {
        navigate(`/teamsports/${sportName}/odds/0/false`);
      } else {
        if (item?.sportId === 4) {
          navigate(
            `/all-sports/${sportName}/${item?.sportId}/odds/${item?.id}/true?menu=fixtures_results&touranamentId=0&touranamentName=All+Leagues`
          );
        } else {
          navigate(`/teamsports/${sportName}/odds/${item?.id}/true`);
        }
      }
    }
  };
  const handleAdvertisingScreen = async () => {
    if (pathname?.includes("advertisingscreen")) {
      setisRunnerLoad(true);
      dispatch(fetchAdvData([], true));
      try {
        const { status, data } = await axiosInstance.get(`events/raceAd`);
        if (status === 200) {
          setisRunnerLoad(false);
          dispatch(fetchAdvData(data, false));
        }
      } catch { }
    }
  };

  const handleGetDate = (dateString) => {
    const currentDate = moment();
    const tomorrowDate = moment().add(1, "day");
    const date = moment(dateString);
    let result;

    if (currentDate.isSame(date, "day")) {
      result = "Today";
    } else if (tomorrowDate.isSame(date, "day")) {
      result = "Tomorrow";
    } else {
      result = date.format("ddd");
    }
    const formattedDate = date.format("DD/MM");
    const formattedTime = date.format("hh:mm A");
    return (
      <>
        <Box className="event-date-wrap">
          <Typography className="date-time">
            {result} {" " + formattedDate}
          </Typography>
          <Typography className="date-time">{formattedTime}</Typography>
        </Box>
      </>
    );
  };
  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeFlag) {
        let TeamLogo = item?.homeFlag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeFlag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeFlag ? (
          <img src={item?.homeFlag} className="team-img" alt="team Icon" />
        ) : (
          <img className="team-img" src={GWS} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={GWS} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayFlag) {
        let TeamLogo = item?.awayFlag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayFlag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayFlag ? (
          <img src={item?.awayFlag} className="team-img" alt="team Icon" />
        ) : (
          <img className="team-img" src={FRE} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={FRE} alt="Odds Icon" />;
      }
    }
  };

  const fetchScoreHeader = (data, teamId, teamtype) => {
    const teamSportsScores =
      teamtype === "hometeam" ? "homeTeamScoreData" : "awayTeamScoreData";
    const teamScore =
      typeof data?.[teamSportsScores] === "string"
        ? JSON.parse(data?.[teamSportsScores])
        : data?.[teamSportsScores];
    return (
      <>
        {teamScore ? (
          data?.sportId !== 11 ? (
            <>
              {/* {(teamScore?.period1 || teamScore?.period1 === 0) && (
            <span>
              {teamScore?.period1}
              {teamScore?.period1TieBreak ? teamScore?.period1TieBreak : ""}
            </span>
          )}
          {(teamScore?.period2 || teamScore?.period2 === 0) && (
            <span>
              {teamScore?.period2}
              {teamScore?.period2TieBreak ? teamScore?.period2TieBreak : ""}
            </span>
          )}
          {(teamScore?.period3 || teamScore?.period3 === 0) && (
            <span>
              {teamScore?.period3}
              {teamScore?.period3TieBreak ? teamScore?.period3TieBreak : ""}
            </span>
          )}
          {(teamScore?.period4 || teamScore?.period4 === 0) && (
            <span>
              {teamScore?.period4}
              {teamScore?.period4TieBreak ? teamScore?.period4TieBreak : ""}
            </span>
          )}
          {(teamScore?.period5 || teamScore?.period5 === 0) && (
            <span>
              {teamScore?.period5}
              {teamScore?.period5TieBreak ? teamScore?.period5TieBreak : ""}
            </span>
          )}
          {(teamScore?.period6 || teamScore?.period6 === 0) && (
            <span>
              {teamScore?.period6}
              {teamScore?.period6TieBreak ? teamScore?.period6TieBreak : ""}
            </span>
          )}
          {(teamScore?.period7 || teamScore?.period7 === 0) && (
            <span>
              {teamScore?.period7}
              {teamScore?.period7TieBreak ? teamScore?.period7TieBreak : ""}
            </span>
          )}
          {(teamScore?.period8 || teamScore?.period8 === 0) && (
            <span>
              {teamScore?.period8}
              {teamScore?.period8TieBreak ? teamScore?.period8TieBreak : ""}
            </span>
          )}
          {(teamScore?.period9 || teamScore?.period9 === 0) && (
            <span>
              {teamScore?.period9}
              {teamScore?.period9TieBreak ? teamScore?.period9TieBreak : ""}
            </span>
          )} */}
              {data?.sportId !== 4 ? (
                (teamScore?.current || teamScore?.current === 0) && (
                  <span className="teamscore-value"> {teamScore?.current}</span>
                )
              ) : (
                //  : teamScore ? (
                //   <span
                //     className={
                //       teamScore?.innings?.inning1 ? "teamscore-value" : `noscore`
                //     }
                //   >
                //     {teamScore?.innings?.inning1
                //       ? teamScore?.innings?.inning1?.score +
                //         "/" +
                //         teamScore?.innings?.inning1?.wickets
                //       : "-"}{" "}
                //     {teamScore?.innings?.inning2
                //       ? ` & ${teamScore?.innings?.inning2?.score}/${teamScore?.innings?.inning2?.wickets}`
                //       : ""}{" "}
                //   </span>
                // )
                <span className="noscore">-</span>
              )}
            </>
          ) : (
            <>
              {/* {teamScore?.innings?.inning1 && (
            <span>{teamScore?.innings?.inning1?.run}</span>
          )}
          {teamScore?.innings?.inning2 && (
            <span>{teamScore?.innings?.inning2?.run}</span>
          )}
          {teamScore?.innings?.inning3 && (
            <span> {teamScore?.innings?.inning3?.run}</span>
          )}
          {teamScore?.innings?.inning4 && (
            <span> {teamScore?.innings?.inning4?.run}</span>
          )}
          {teamScore?.innings?.inning5 && (
            <span> {teamScore?.innings?.inning5?.run}</span>
          )}
          {teamScore?.innings?.inning6 && (
            <span> {teamScore?.innings?.inning6?.run}</span>
          )}
          {teamScore?.innings?.inning7 && (
            <span> {teamScore?.innings?.inning7?.run}</span>
          )}
          {teamScore?.innings?.inning8 && (
            <span> {teamScore?.innings?.inning8?.run}</span>
          )}
          {teamScore?.innings?.inning9 && (
            <span> {teamScore?.innings?.inning9?.run}</span>
          )} */}
              {(teamScore?.current || teamScore?.current === 0) && (
                <span className="teamscore-value">{teamScore?.current}</span>
              )}
              {/* {teamSportType === "baseball" && teamScore?.inningsBaseball && (
            <>
              <span>{teamScore?.inningsBaseball?.hits}</span>
              <span>{teamScore?.inningsBaseball?.errors}</span>
            </>
          )} */}
            </>
          )
        ) : (
          <span className="noscore">-</span>
        )}
      </>
    );
  };
  const fetchCricketScore = (teamScore, teamtype) => {
    return teamtype === "hometeam" ? (
      teamScore?.Exd > "1" ? (
        <span className="team-score teamscore-value">
          {teamScore?.Tr1C1 ?? "-"}
          {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
            ? ""
            : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
            `/${teamScore?.Tr1CW1}${teamScore?.Tr1CD1 && teamScore?.Tr1CD1 === 1 ? "d" : ""
            }`}{" "}
          {(teamScore?.Tr1C2 || teamScore?.Tr1C2 === 0) &&
            `& ${teamScore?.Tr1C2}`}
          {teamScore?.Tr1CW2 && teamScore?.Tr1CW2 === 10
            ? ""
            : (teamScore?.Tr1CW2 || teamScore?.Tr1CW2 === 0) &&
            `/${teamScore?.Tr1CW2}${teamScore?.Tr1CD2 && teamScore?.Tr1CD2 === 1 ? "d" : ""
            }`}{" "}
        </span>
      ) : (
        <span className="team-score teamscore-value">
          {teamScore?.Tr1C1 ?? "-"}
          {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
            ? ""
            : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
            `/ ${teamScore?.Tr1CW1}`}{" "}
          {/* <span>
          {" "}
          {teamScore?.Tr1CO1 ? `(${teamScore?.Tr1CO1})` : ""}
        </span>{" "} */}
        </span>
      )
    ) : teamScore?.Exd > "1" ? (
      <span className="team-score teamscore-value">
        {teamScore?.Tr2C1 ?? "-"}
        {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
          ? ""
          : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
          `/${teamScore?.Tr2CW1}${teamScore?.Tr2CD1 && teamScore?.Tr2CD1 === 1 ? "d" : ""
          }`}{" "}
        {(teamScore?.Tr2C2 || teamScore?.Tr2C2 === 0) &&
          `& ${teamScore?.Tr2C2}`}
        {teamScore?.Tr2CW2 && teamScore?.Tr2CW2 === 10
          ? ""
          : (teamScore?.Tr2CW2 || teamScore?.Tr2CW2 === 0) &&
          `/${teamScore?.Tr2CW2}${teamScore?.Tr2CD2 && teamScore?.Tr2CD2 === 1 ? "d" : ""
          }`}{" "}
      </span>
    ) : (
      <span className="team-score teamscore-value">
        {teamScore?.Tr2C1 ?? "-"}
        {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
          ? ""
          : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
          `/ ${teamScore?.Tr2CW1}`}{" "}
        {/* <span>
          {teamScore?.Tr2CO1 ? `(${teamScore?.Tr2CO1})` : ""}
        </span> */}
      </span>
    );
  };

  const fetchSoccerScore = (teamScore, teamtype) => {
    return teamtype === "hometeam" ? (
      <span className="team-score teamscore-value">
        {teamScore?.Tr1 ?? "-"}
      </span>
    ) : (
      <span className="team-score teamscore-value">
        {teamScore?.Tr2 ?? "-"}
      </span>
    );
  };

  // const handleComplete = () => {
  //   if (!hasCompletedRef.current) {
  //     hasCompletedRef.current = true;

  //     // Your API call logic here
  //     setTimeout(() => {
  //       setPrevOffset(0);
  //       setNextOffset(1);
  //       setDisableNext(false);
  //       setDisablePrev(false);
  //       GetUpcomingRace(false, 1);
  //       handleAdvertisingScreen();
  //     }, 2000);
  //   }
  // };

  const renderSliderItem = (slide, i) => {
    return (
      <>
        <div
          key={i}
          variant="outlined"
          className="cursor slide-item-1 new-slider-next topbar-slider"
          onClick={() => handleRaceNavigation(slide)}
        >
          <span className="slider-list-1">
            <Typography className="list-header">
              <img
                src={raceIcon(
                  slide?.sportName
                    ? slide?.sportName?.trim()
                    : slide?.sportName?.trim()
                )}
                alt="race-icon"
              />
              <span className="sport-name">
                {slide?.sportId === 1 ||
                  slide?.sportId === 2 ||
                  slide?.sportId === 3
                  ? slide?.sportName
                    ? slide?.sportName
                    : ""
                  : slide?.tournamentName
                    ? slide?.tournamentName
                    : ""}
              </span>
            </Typography>
            {slide?.status === "inprogress" || slide?.Epr === 1 ? (
              <Box className="event-date-wrap live-wrap">
                <Typography className="Live">Live</Typography>
                <Typography className="date-time"> Scores</Typography>
              </Box>
            ) : (
              handleGetDate(slide?.eventDate)
            )}

            {/* {/* For Reacing /*} */}

            {slide?.sportId === 1 ||
              slide?.sportId === 2 ||
              slide?.sportId === 3 ? (
              <Box className="event-countDown">
                <span className="slide-title">
                  <span>{`R${slide?.raceNumber} ${slide?.trackName}`}</span>
                </span>
                {slide?.resultDisplay && slide?.resultDisplay != "" ? (
                  <span className="race-result">{slide?.resultDisplay}</span>
                ) : moment(
                  moment.utc(slide?.eventDate).local().toDate()
                ).isBefore(new Date()) ? (
                  <Chip
                    className="bg-orange sqare-chip "
                    size="small"
                    label={<span> Closed </span>}
                  />
                ) : (
                  <>
                    <Countdown
                      date={moment.utc(slide?.eventDate).local().toDate()}
                      renderer={renderer}
                      onComplete={() => {
                        return setTimeout(() => {
                          setPrevOffset(0);
                          setNextOffset(1);
                          setDisableNext(false);
                          setDisablePrev(false);
                          GetUpcomingRace(false, 1);
                          handleAdvertisingScreen();
                        }, 2000);
                      }}
                      key={moment.utc(slide?.eventDate).local().toDate()}
                    />
                  </>
                )}
              </Box>
            ) : (
              <Box className="sport-event">
                <Box
                  className={
                    slide?.winnerCode
                      ? slide?.winnerCode === 2
                        ? "light d-flex text-align content-space-betwen team-deatils"
                        : "d-flex text-align content-space-betwen team-deatils"
                      : "d-flex text-align content-space-betwen team-deatils"
                  }
                >
                  <Box className="d-flex text-align">
                    <Box className="team-logo">
                      {fetchTeamlogo(slide, "hometeam")}
                    </Box>

                    <Box className="live-icon  d-flex text-align">
                      <Typography className="team-name w-80">
                        {slide?.homeName ? slide?.homeName : ""}
                      </Typography>
                      {slide?.status === "inprogress" || slide?.Epr === 1 ? (
                        slide?.sportId === 4 ? (
                          slide?.Ebat === 1 ? (
                            <span>
                              <Batting />
                            </span>
                          ) : (
                            <span>
                              <Bowling />
                            </span>
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                  <Box className="team-time-result">
                    <Typography className="result-details">
                      {" "}
                      {slide?.sportId === 4
                        ? fetchCricketScore(slide, "hometeam")
                        : slide?.sportId === 8
                          ? fetchSoccerScore(slide, "hometeam")
                          : fetchScoreHeader(
                            slide,
                            slide?.homeTeamId,
                            "hometeam"
                          )}
                    </Typography>
                    <span className="win-icon">
                      {slide?.winnerCode ? (
                        slide?.winnerCode === 1 ? (
                          <WinArrow />
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </span>
                  </Box>
                </Box>
                <Box
                  className={
                    slide?.winnerCode
                      ? slide?.winnerCode === 1
                        ? "light d-flex text-align content-space-betwen"
                        : "d-flex text-align content-space-betwen"
                      : "d-flex text-align content-space-betwen"
                  }
                >
                  <Box className="d-flex text-align">
                    <Box className="team-logo">
                      {fetchTeamlogo(slide, "awayteam")}
                    </Box>
                    <Box className="live-icon  d-flex text-align">
                      <Typography className="team-name w-80">
                        {" "}
                        {slide?.awayName ? slide?.awayName : ""}
                      </Typography>
                      {slide?.status === "inprogress" || slide?.Epr === 1 ? (
                        slide?.sportId === 4 ? (
                          slide?.Ebat === 2 ? (
                            <span>
                              <Batting />
                            </span>
                          ) : (
                            <span>
                              <Bowling />
                            </span>
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                  <Box className="team-time-result">
                    <Typography className="result-details">
                      {" "}
                      {slide?.sportId === 4
                        ? fetchCricketScore(slide, "awayteam")
                        : slide?.sportId === 8
                          ? fetchSoccerScore(slide, "awayteam")
                          : fetchScoreHeader(
                            slide,
                            slide?.awayTeamId,
                            "awayteam"
                          )}
                    </Typography>
                    <span className="win-icon">
                      {slide?.winnerCode ? (
                        slide?.winnerCode === 2 ? (
                          <WinArrow />
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </span>
                  </Box>
                </Box>
              </Box>
            )}
          </span>
        </div>
      </>
    );
  };

  const raceIcon = (name) => {
    if (name === "Horse Racing") {
      return horse;
    } else if (name === "Harness Racing") {
      return harnes;
    } else if (name === "Greyhound Racing") {
      return greys;
    } else if (name === "American Football") {
      return Football;
    } else if (name === "Australian Rules") {
      return AR;
    } else if (name === "Baseball") {
      return Baseball;
    } else if (name === "Basketball") {
      return Basketball;
    } else if (name === "Boxing") {
      return Boxing;
    } else if (name === "Cricket") {
      return Cricket;
    } else if (name === "Golf") {
      return Golf;
    } else if (name === "Ice Hockey") {
      return IceHockey;
    } else if (name === "Mixed Martial Arts") {
      return MMA;
    } else if (name === "Rugby League") {
      return Rugby;
    } else if (name === "Rugby Union") {
      return RU;
    } else if (name === "Soccer") {
      return Soccer;
    } else if (name === "Tennis") {
      return Tennis;
    }
  };

  const racingData = reduxBannerData;
  let upcomingRacesSlides = racingData;

  useEffect(() => {
    if (carouselRef.current && upcomingRacesSlides?.length > 0) {
      const slidesToShow = carouselRef.current.props.slidesToShow;
    }
  }, [carouselRef, upcomingRacesSlides]);

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slickPrev(); // use slickPrev to go to the previous slide
    }
    if (!isLive) {
      setTimeout(() => {
        if (!disablePrev) {
          const currentIndex = Math.ceil(activeSlide);
          if (
            currentIndex ===
            48 - 2 * carouselRef.current?.props?.slidesToShow &&
            !prevData?.length
          ) {
            fetchpastResultdata(prevOffset + 1);
          } else if (currentIndex === 0) {
            setActiveSlide(currentIndex);
            setActivePageState(currentIndex);
            setIsTopBarLoading(true);
            prevData ?? fetchpastResultdata(prevOffset + 1);
            prevData?.length && MergePrevData();
          }
        }
      }, 50);
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slickNext();
    }
    const currentIndex = Math.ceil(
      activeSlide / carouselRef.current?.props?.slidesToShow
    );
    const maxIndex =
      Math.ceil(
        upcomingRacesSlides.length / carouselRef.current?.props?.slidesToShow
      ) - 1;
    if (!disableNext) {
      if (currentIndex === maxIndex - 7 && !nextData?.length) {
        fetchNextResultdata(nextOffset + 1);
      } else if (currentIndex === maxIndex) {
        setActiveSlide(currentIndex);
        setActivePageState(currentIndex);
        setMaxIndexState(maxIndex);
        setIsTopBarLoading(true);
        nextData?.length && MergeNextData();
      }
    }
  };

  // const handleNextClick = () => {
  //   carouselRef.current && carouselRef.current.slideNext();

  //   if (!disableNext) {
  //     const currentIndex = carouselRef.current?.state?.activePage;
  //     const maxIndex = carouselRef.current?.state?.pages?.slice(-1)[0];
  //     if (currentIndex === maxIndex - 7 && !nextData?.length) {
  //       fetchNextResultdata(nextOffset + 1);
  //     } else if (currentIndex === maxIndex) {
  //       setActivePageState(currentIndex);
  //       setMaxIndexState(maxIndex);
  //       setIsTopBarLoading(true);
  //       nextData?.length && MergeNextData();
  //     }
  //   }
  // };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow:
      upcomingRacesSlides?.length >= 6
        ? 6
        : upcomingRacesSlides?.length >= 5
          ? 5
          : upcomingRacesSlides?.length >= 4
            ? 4
            : upcomingRacesSlides?.length >= 3
              ? 3
              : upcomingRacesSlides?.length >= 2
                ? 2
                : upcomingRacesSlides?.length >= 1
                  ? 1
                  : 6,
    slidesToScroll: 6,
    initialSlide: sliderIndex,
    arrows: false,
    responsive: [
      { breakpoint: 1120, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      { breakpoint: 940, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 560, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  return (
    <>
      {isTopBarLoading ? (
        <div className="allsport-loader-center topbar-loader">
          <Loader />
        </div>
      ) : (
        <>
          <div className="NextoJump-Item NextoJump-Nav">
            {isTopBarLoading ? (
              <div className="allsport-loader-center topbar-loader">
                <Loader />
              </div>
            ) : (
              <>
                <button onClick={handlePrevClick} aria-label="pre-slide">
                  <PrvSlide height="28px" width="28px" />
                </button>
              </>
            )}
          </div>
          {upcomingRacesSlides?.length > 0 ? (
            <>
              <div className="NextoJump-Item NextoJump-RaceSlider-V2">
                <Slider
                  ref={carouselRef}
                  {...settings}
                  beforeChange={(oldIndex, newIndex) =>
                    setActiveSlide(newIndex)
                  }
                >
                  {upcomingRacesSlides?.map((slide, i) =>
                    renderSliderItem(slide, i)
                  )}
                </Slider>
              </div>
            </>
          ) : (
            <Box className="no-match-available">
              <Typography className="no-data">
                There are currently no scheduled matches and races available
                from your selection.
              </Typography>
            </Box>
          )}
          <div className="NextoJump-Item NextoJump-Nav">
            {isTopBarLoading ? (
              <div className="allsport-loader-center topbar-loader">
                <Loader />
              </div>
            ) : (
              <>
                <button onClick={handleNextClick} aria-label="next-slide">
                  <NextSlide height="28px" width="28px" />
                </button>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default UpcommingCarousel;
