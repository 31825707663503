import { useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import Loader from "src/components/Loader";
import { NRLStaticColumn } from "./NRLStaticColumn";
import { useLadder } from "src/helpers/context/Ladder/LadderContext";
import { AFLStaticColumn } from "./AFLStaticColumn";
import { useParams } from "react-router";
import { SPORTS_TYPE } from "../../../teamCommonComponent/newSportsUtils";

const LadderTable = () => {
  const { tournamentList, loading } = useLadder();
  const params = useParams();
  const sportsId = Number(params?.sportsId);

  const columns = useMemo(
    () =>
      sportsId === SPORTS_TYPE.australianrules
        ? AFLStaticColumn
        : NRLStaticColumn,
    [sportsId],
  );

  const table = useReactTable({
    data: tournamentList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
  });

  return (
    <Box className="table-container-details">
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          className="new-landing-table"
        >
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    className="sorting-cell"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <div className="allsport-loader-center ">
                    <Loader />
                  </div>
                </TableCell>
              </TableRow>
            ) : tournamentList.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} align="center">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <div className="allsport-loader-center">
                    No Data Available
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LadderTable;
