import { useParams } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";

const { createContext, useContext, useState, useEffect } = require("react");

const ladderContext = createContext();

const LadderStatsProvider = ({ children }) => {
  const [tournamentList, setTournamentList] = useState([]);
  const [roundList, setRoundList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [round, setRound] = useState(1);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const params = useParams();
  const sportsId = Number(params?.sportsId);
  const getNRLLadderStats = async ({ round, seasonId, sportsId }) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        `sports/statistics/tournament/ladder/1?SportId=${sportsId}&seasonId=${seasonId}&round=${round}`,
      );
      if (data?.result) {
        setTournamentList(
          data.result.map((item, index) => ({
            ...item,
            position: index + 1,
            difference: 0,
          })),
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getRoundList = async ({ seasonId, sportsId }) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        `sports/statistics/round/1?SportId=${sportsId}&seasonId=${seasonId}`,
      );
      if (data?.result) {
        setRoundList(data.result);
        setRound(data.result?.[0]?.round);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (round && selectedSeason && sportsId) {
      getNRLLadderStats({ round, seasonId: selectedSeason, sportsId });
    }
  }, [round, selectedSeason, sportsId]);

  useEffect(() => {
    if (selectedSeason && sportsId) {
      getRoundList({ seasonId: selectedSeason, sportsId });
    }
  }, [selectedSeason, sportsId]);

  const ladderState = {
    tournamentList,
    roundList,
    loading,
    round,
    setRound,
    selectedLeague,
    setSelectedLeague,
    selectedSeason,
    setSelectedSeason,
  };

  return (
    <ladderContext.Provider value={ladderState}>
      {children}
    </ladderContext.Provider>
  );
};

export const useLadder = () => {
  return useContext(ladderContext);
};

export default LadderStatsProvider;
